import type { EmptyTuple, WithDefault } from "camelcase-keys";
import { isPrimitive } from "es-toolkit";
import snakecaseKeysInternal from "snakecase-keys";

function snakecaseKeys<T, Options extends snakecaseKeysInternal.Options>(
  input: T,
  options?: Options,
): snakecaseKeysInternal.SnakeCaseKeys<
  T,
  Options["deep"] extends boolean ? Options["deep"] : true,
  WithDefault<Options["exclude"], EmptyTuple>
> {
  if (isPrimitive(input)) {
    // @ts-expect-error -- Complex type.
    return input;
  }
  if (Array.isArray(input)) {
    // @ts-expect-error -- Complex type.
    return input.map((item) => snakecaseKeys(item, options));
  }
  // @ts-expect-error -- Wrong type definition in the original package. Tested.
  return snakecaseKeysInternal(input, options);
}

export { snakecaseKeys };
