import { noop } from "es-toolkit";
import { type AnyStore, computed } from "nanostores";

interface Effect {
  <const T>(
    store: AnyStore<T>,
    callback: (value: T) => void | VoidFunction,
  ): () => void;
  <const TStores extends Array<AnyStore>>(
    stores: TStores,
    callback: (
      ...values: {
        [TIndex in keyof TStores]: TStores[TIndex] extends AnyStore<infer U>
          ? U
          : never;
      }
    ) => void | VoidFunction,
  ): () => void;
}

/**
 * ```ts
 * const destroy = effect([...$stores], ([value1, ...values]) => {
 *   // do something with values
 *   return function cleanup() {
 *   }
 * })
 * ```
 */
const effect: Effect = (
  store: AnyStore | Array<AnyStore>,
  callback: (...values: Array<AnyStore>) => void | VoidFunction,
) => {
  const stores = Array.isArray(store) ? store : [store];
  let lastCleanup: ReturnType<typeof callback>;
  const $cleanup = computed(stores, (...values) => {
    lastCleanup && lastCleanup();
    lastCleanup = callback(...values);
  });
  /**
   * A subscription is required to trigger the effect when the store changes.
   */
  const unsubscribe = $cleanup.subscribe(noop);
  return function cleanup() {
    lastCleanup && lastCleanup();
    unsubscribe();
  };
};

export { effect };
