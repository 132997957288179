// cspell:words onreconnect
import type { Overwrite } from "@mui/types";
import type { ClSdk } from "../../ClSdk";
import mitt from "mitt";
import { nanoid } from "nanoid";
import { atom } from "nanostores";

import WebsocketHeartbeatJsLib from "websocket-heartbeat-js";
import { ClDebug } from "../ClDebug";

const WebsocketHeartbeatJs: typeof WebsocketHeartbeatJsLib =
  // @ts-expect-error -- Workaround for CJS interop issue.
  WebsocketHeartbeatJsLib.default || WebsocketHeartbeatJsLib;

namespace Ws {
  export interface Options
    extends Overwrite<
      ConstructorParameters<typeof WebsocketHeartbeatJs>[0],
      {
        clSdk: ClSdk;
      }
    > {}

  export type State =
    | "connecting"
    | "opened"
    | "reconnecting"
    | "closing"
    | "closed";
  // eslint-disable-next-line ts/consistent-type-definitions -- Prefer `type` over `interface` for improved autocompletion and type checking.
  export type EventMap = {
    close: CloseEvent;
    error: Event;
    open: Event;
    message: MessageEvent;
    reconnect: void;
  };
}

class Ws {
  private wsId = nanoid();
  private clDebug: ClDebug;
  stores = {
    $state: atom<Ws.State>("connecting"),
  };
  mitt = mitt<Ws.EventMap>();
  ws: WebsocketHeartbeatJsLib;
  constructor({ clSdk, ...opts }: Ws.Options) {
    this.clDebug = new ClDebug({ clSdk, module: `Ws:${this.wsId}` });

    this.ws = new WebsocketHeartbeatJs(opts);
    this.ws.onclose = (event) => {
      this.clDebug.debug("close", event);
      this.mitt.emit("close", event);
    };
    this.ws.onerror = (event) => {
      this.clDebug.error("error", event);
      this.mitt.emit("error", event);
    };
    this.ws.onopen = (event) => {
      this.clDebug.debug("open", event);
      this.mitt.emit("open", event);
    };
    this.ws.onmessage = (event) => {
      this.clDebug.debug("message", event);
      this.mitt.emit("message", event);
    };
    this.ws.onreconnect = () => {
      this.clDebug.debug("reconnect");
      this.mitt.emit("reconnect");
    };
  }
  close() {
    this.ws.close();
  }
}

export { Ws };
