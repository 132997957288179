import type { Ref, RefCallback } from "react";

/**
 * <https://github.com/gregberge/react-merge-refs/issues/36>
 */
function mergeRefs<T>(...refs: Array<Ref<T> | undefined>): RefCallback<T> {
  return (value) => {
    const cleanups = refs.reduce<Array<VoidFunction>>((accumulator, ref) => {
      if (typeof ref === "function") {
        const cleanup = ref(value);
        if (typeof cleanup === "function") {
          accumulator.push(cleanup);
        }
      } else if (ref) {
        ref.current = value;
      }

      return accumulator;
    }, []);

    return () => {
      cleanups.forEach((cleanup) => cleanup());
    };
  };
}

export { mergeRefs };
