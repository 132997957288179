import type { ClassNameRecord } from "../../../../../../types/ClassNameRecord";
import { getToken } from "../../../../../../theme/tokens";
import { defineCssVarUtils } from "../../../../../../utils/defineCssVarUtils";

const cssVarUtils = defineCssVarUtils({
  "--cl-widget-sender-margin": "32px",
  "--cl-widget-bubble-bg-color": getToken("--color-widget-color-blue-50"),
  "--cl-widget-bubble-fg-color": getToken(
    "--cl-widget-color-static-fg-superior",
  ),
});

const classNameRecord = {
  root: "cl-widget-chat-message",
  byMe: "cl-widget-by-me",
  sentAt: "cl-widget-sentAt",
} satisfies ClassNameRecord;

const messageStyleUtils = {
  classNameRecord,
  cssVarUtils,
};

export { messageStyleUtils };
