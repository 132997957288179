import { ClWidget } from "./ClWidget";
import { createClWidget } from "./createClWidget";

if (typeof window !== "undefined") {
  if (!window.ClWidget) window.ClWidget = ClWidget;
  else console.warn("ClWidget is already defined on window object");
  if (!window.createClWidget) window.createClWidget = createClWidget;
  else console.warn("createClWidget is already defined on window object");
  window.onClWidgetScriptLoad?.({
    createClWidget,
  });
}
