import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { SxPropsRecord } from "../../../../../../types/SxPropsRecord";

import { Box, type BoxProps } from "@mui/material";
import useEventCallback from "@mui/utils/useEventCallback";

import React, { type ComponentProps, useEffect, useMemo, useRef } from "react";
import Viewer from "viewerjs";
import { defuSx } from "../../../../../../theme/defuSx";
import { mergeRefs } from "../../../../../../utils/mergeRefs";

const sxPropsRecord = {
  root: (theme) => ({
    width: "50%",
    minWidth: 20,
    borderRadius: 2,
    aspectRatio: 1,
    backgroundColor: theme.vars.palette.grey["100"],
    img: {
      width: "100%",
      height: "100%",
      aspectRatio: 1,
      cursor: "pointer",
      objectFit: "cover",
    },
  }),
  viewer: {
    "&&": {
      ".viewer-prev, .viewer-play, .viewer-next": {
        display: "none",
      },
    },
  },
} satisfies SxPropsRecord;

namespace ImgMessage {
  export interface Props
    extends Overwrite<
      ComponentProps<"img">,
      {
        containerProps?: DistributiveOmit<BoxProps, "children" | "component">;
      }
    > {}
}

const ImgMessage: React.FC<ImgMessage.Props> = ({
  containerProps,
  ...props
}) => {
  const localViewerContainerRef = useRef<React.ComponentRef<"div">>(null);
  const viewerContainerRef: React.ComponentProps<"div">["ref"] =
    useEventCallback((node) =>
      mergeRefs(containerProps?.ref, localViewerContainerRef)(node),
    );
  const localImageRef = useRef<React.ComponentRef<"img">>(null);
  const imageRef: React.ComponentProps<"img">["ref"] = useEventCallback(
    (node) => mergeRefs(props.ref, localImageRef)(node),
  );
  useEffect(() => {
    const viewerContainerElement = localViewerContainerRef.current;
    const imageElement = localImageRef.current;
    if (!viewerContainerElement || !imageElement) return;

    const viewer = new Viewer(imageElement, {
      inline: false,
      viewed() {
        viewer.zoomTo(1);
      },
      container: viewerContainerElement,
      loop: false,
      navbar: false,
    });
    return () => {
      viewer.destroy();
    };
  }, []);
  const containerSx = useMemo(
    () => defuSx(containerProps?.sx, sxPropsRecord.root),
    [containerProps?.sx],
  );
  return (
    <Box {...containerProps} sx={containerSx}>
      <img tabIndex={0} {...props} ref={imageRef} />
      <Box sx={sxPropsRecord.viewer} ref={viewerContainerRef} />
    </Box>
  );
};

export { ImgMessage };
