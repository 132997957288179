import { makeApi } from "@zodios/core";

import { ChannelConfigSchema, ClWebChannelIdSchema } from "../../model";

const apiChannelConfig = makeApi([
  {
    alias: "getConfig",
    method: "get",
    path: "/api/v1/clients/:clWebChannelId/config",
    parameters: [
      {
        type: "Path",
        name: "clWebChannelId",
        schema: ClWebChannelIdSchema,
      },
    ],
    response: ChannelConfigSchema,
  },
]);

export { apiChannelConfig };
