import { Badge, type BadgeProps, type BadgeTypeMap } from "@mui/material";

namespace ClBadge {
  export type Props<
    RootComponent extends React.ElementType = BadgeTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = BadgeProps<RootComponent, AdditionalProps>;

  export type Type = typeof Badge;
}

const ClBadge: ClBadge.Type = <
  RootComponent extends React.ElementType = BadgeTypeMap["defaultComponent"],
  AdditionalProps = object,
>(
  props: ClBadge.Props<RootComponent, AdditionalProps>,
) => {
  return <Badge {...props} />;
};

export { ClBadge };
