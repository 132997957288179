import type { ClSdk } from "../../ClSdk";
import type { SignedUrl } from "../../model";
import type { ClUploadExecution } from "./ClUploadExecution";
import { nanoid } from "nanoid";
import { atom } from "nanostores";
import { ClDebug } from "../ClDebug";

namespace ClUploadTask {
  export interface Options {
    clSdk: ClSdk;
    file: File;
    abortController?: AbortController;
  }
  export type State =
    | {
        status: "idle";
        progress: 0;
        result: undefined;
        error: undefined;
      }
    | {
        status: "uploading";
        progress: number;
        result: undefined;
        error: undefined;
      }
    | {
        status: "done";
        progress: 1;
        result: SignedUrl;
        error: undefined;
      }
    | {
        status: "error";
        progress: number;
        result: undefined;
        error: unknown;
      };
}

class ClUploadTask {
  readonly id = nanoid();
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  private file: File;
  readonly execution: ReturnType<ClUploadExecution["upload"]>;
  private abortController: AbortController;
  $state = atom<ClUploadTask.State>({
    status: "idle",
    progress: 0,
    result: undefined,
    error: undefined,
  });
  constructor(options: ClUploadTask.Options) {
    this.clSdk = options.clSdk;
    this.file = options.file;
    this.abortController = options.abortController || new AbortController();
    this.clDebug = new ClDebug({
      clSdk: this.clSdk,
      module: `ClUploadTask:${this.id}`,
    });
    this.clDebug.debug("Initialized", {
      file: this.file,
      signal: this.abortController,
    });
    this.execution = this.clSdk.clUploadExecution.upload({
      file: this.file,
      signal: this.abortController.signal,
    });
    this.execution.promise.then(
      (result) => {
        this.clear();
        this.$state.set({
          status: "done",
          progress: 1,
          result,
          error: undefined,
        });
      },
      (error) => {
        this.clear();
        this.$state.set({
          ...this.$state.get(),
          status: "error",
          result: undefined,
          error,
        });
      },
    );
    this.execution.eventEmitter.on("progress", this.progressHandler);
  }
  progressHandler = (progress: ClUploadExecution.UploadEvents["progress"]) => {
    this.$state.set({
      ...this.$state.get(),
      status: "uploading",
      progress,
      result: undefined,
      error: undefined,
    });
  };
  clear = () => {
    this.execution.eventEmitter.off("progress", this.progressHandler);
  };
  abort = () => {
    this.abortController.abort();
  };
  destroy = () => {
    this.abortController.abort();
    this.clear();
  };
}

export { ClUploadTask };
