import type { SxPropsRecord } from "../../../../../../types/SxPropsRecord";
import React from "react";
import { ClIcon } from "../../../../../../components/ClIcon";
import { getToken } from "../../../../../../theme/tokens";
import { Bubble } from "./Bubble";
import { messageStyleUtils } from "./messageStyleUtils";

const sxPropsRecord = {
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    fontWeight: 500,
    gap: "4px",
    hyphens: "none",
    wordBreak: "break-all",
    "&:not(:hover, :focus, :active)": { textDecoration: "none" },
  },
  icon: {
    fontSize: "16px",
    color: getToken("--cl-widget-color-static-fg-note"),
    translate: "0 2px",
    [`&:not(.${messageStyleUtils.classNameRecord.byMe} &)`]: {
      color: "inherit",
    },
  },
} satisfies SxPropsRecord;

namespace FileMessage {
  export interface Props {
    filename: string;
    url: string;
  }
}

const FileMessage: React.FC<FileMessage.Props> = ({ filename, url }) => {
  return (
    <Bubble
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      download
      sx={sxPropsRecord.root}
    >
      <ClIcon icon="Attachment" sx={sxPropsRecord.icon} />
      <span>{filename}</span>
    </Bubble>
  );
};

export { FileMessage };
