import type { MessageSchema } from "@chatbotgang/web-sdk-core/model";

import type { ClDialogue } from "@chatbotgang/web-sdk-core/modules/ClChat/ClDialogue";
import type { z } from "zod";
import useEventCallback from "@mui/utils/useEventCallback";
import { findLastIndex } from "es-toolkit/compat";
import {
  type ComponentRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useClSdkDefaultUiOptions } from "../../../../context";
import { BottomPanel } from "../Dialogue/BottomPanel";
import { Messages } from "../Dialogue/Messages";

namespace Dialogue {
  export interface Props {
    onSendMessage?: (message: ClDialogue.SendMessageOptions) => void;
    messages: Array<z.infer<typeof MessageSchema>>;
    onMessageRead?: Messages.Props["onMessageRead"];
  }
  export type ScrollingTask = "bottom" | "unread";
}

function isScrollAtBottom(element: HTMLElement) {
  return (
    Math.ceil(
      element.scrollHeight - element.scrollTop - element.clientHeight,
    ) <= 1
  );
}

function scrollToBottom(element: HTMLElement) {
  element.scrollTo({
    top: element.scrollHeight,
  });
}

const scrollingTaskTimeoutMs = 50;

const Dialogue: React.FC<Dialogue.Props> = (props) => {
  const clSdkDefaultUiOptions = useClSdkDefaultUiOptions();
  const [messages, setMessages] = useState(props.messages);
  const [containerEl, containerRef] = useState<HTMLDivElement | null>(null);
  const [contentEl, contentRef] = useState<HTMLDivElement | null>(null);
  const [scrolledToBottom, setScrolledToBottom] = useState(true);
  const scrollingTaskRef = useRef<null | {
    timeout: ReturnType<typeof setTimeout>;
    task: Dialogue.ScrollingTask;
  }>(null);
  const clearScrollingTask = useEventCallback(() => {
    const scrollingToBottomTimeout = scrollingTaskRef.current?.timeout;
    if (!scrollingToBottomTimeout) return;
    clearTimeout(scrollingToBottomTimeout);
    scrollingTaskRef.current = null;
  });
  const resetScrollingTask = useEventCallback(
    (task: Dialogue.ScrollingTask) => {
      clearScrollingTask();
      scrollingTaskRef.current = {
        timeout: setTimeout(clearScrollingTask, scrollingTaskTimeoutMs),
        task,
      };
    },
  );
  const scrollToBottomFn = useEventCallback(() => {
    if (!containerEl) return;
    resetScrollingTask("bottom");
    scrollToBottom(containerEl);
  });

  const messagesMutationBeforeHook = useEventCallback(
    function messagesMutationBeforeHook():
      | undefined
      | {
          type: "bottom";
        }
      | {
          type: "element";
          containerElement: HTMLElement;
          element: HTMLElement;
          rect: DOMRect;
        } {
      if (!containerEl) return;
      const isScrollingToBottom = Boolean(
        scrollingTaskRef.current?.task === "bottom",
      );
      if (scrolledToBottom || isScrollingToBottom) {
        return {
          type: "bottom",
        };
      }
      if (isScrollAtBottom(containerEl)) {
        return {
          type: "bottom",
        };
      }
      const firstMessageInView = containerEl.querySelectorAll("*>*");
      for (let i = 0; i < firstMessageInView.length; i++) {
        const element = firstMessageInView[i];
        if (!(element instanceof HTMLElement)) continue;
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0) {
          return {
            type: "element",
            containerElement: containerEl,
            element,
            rect,
          };
        }
      }
      return undefined;
    },
  );
  const messagesMutationAfterHook = useEventCallback(
    async function messagesMutationAfterHook(
      data: ReturnType<typeof messagesMutationBeforeHook>,
    ) {
      if (!data) return;
      if (data.type === "bottom") {
        scrollToBottomFn();
        return;
      }
      data.containerElement.scrollTo({
        top:
          data.containerElement.scrollTop +
          data.element.getBoundingClientRect().top -
          data.rect.top,
      });
    },
  );
  /**
   * Update local messages state when messages prop changes.
   */
  useEffect(() => {
    const data = messagesMutationBeforeHook();
    setMessages(props.messages);
    const timeout = setTimeout(() => {
      messagesMutationAfterHook(data);
    }, 1);
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [messagesMutationAfterHook, messagesMutationBeforeHook, props.messages]);
  const stickBottomHandler = useEventCallback(() => {
    if (!containerEl) return;
    const scrollingTask = scrollingTaskRef.current;
    if (scrollingTask && scrollingTask.task !== "bottom") return;
    const scrollingToBottom: boolean = Boolean(
      scrollingTask && scrollingTask.task === "bottom",
    );
    if (!scrollingToBottom && !scrolledToBottom) return;
    scrollToBottomFn();
  });
  useEffect(() => {
    window.addEventListener("resize", stickBottomHandler);
    return function cleanup() {
      window.removeEventListener("resize", stickBottomHandler);
    };
  }, [containerEl, stickBottomHandler]);
  useEffect(() => {
    if (!containerEl || !contentEl) return;
    const observer = new ResizeObserver(stickBottomHandler);
    observer.observe(containerEl);
    observer.observe(contentEl);
    return function cleanup() {
      observer.disconnect();
    };
  });
  const findFirstUnreadMessage = useEventCallback(() => {
    if (messages.length === 0) return;
    const lastSenderMessageIndex = findLastIndex(
      messages,
      (message) => message.role === "sender",
    );
    const firstUnreadMessage = messages.find(
      (message, index) =>
        (lastSenderMessageIndex === -1 || index > lastSenderMessageIndex) &&
        message.role === "receiver" &&
        !message.readAt,
    );
    return firstUnreadMessage;
  });
  const [firstUnreadMessage, setFirstUnreadMessage] = useState(
    findFirstUnreadMessage,
  );
  /**
   * Only update when being opened.
   */
  const updateFirstUnreadMessage = useEventCallback(() => {
    setFirstUnreadMessage(findFirstUnreadMessage());
  });
  const allMessagesUnread = useMemo(
    () => messages.length > 0 && firstUnreadMessage === messages[0],
    [firstUnreadMessage, messages],
  );
  const allMessagesRead = useMemo(
    () => messages.length > 0 && !firstUnreadMessage,
    [firstUnreadMessage, messages.length],
  );
  const messageItems = useMemo(() => {
    if (messages.length === 0) return [];
    const firstUnreadMessageIndex = !firstUnreadMessage
      ? -1
      : messages.findIndex((message) => message.id === firstUnreadMessage.id);
    return messages.map((message, index) => ({
      message,
      read: allMessagesRead
        ? true
        : allMessagesUnread
          ? false
          : index < firstUnreadMessageIndex,
    }));
  }, [allMessagesRead, allMessagesUnread, firstUnreadMessage, messages]);
  const textAreaRef = useRef<ComponentRef<"textarea">>(null);
  useLayoutEffect(() => {
    if (!textAreaRef.current) return;
    if (!clSdkDefaultUiOptions?.autoFocusChatInput) return;
    textAreaRef.current.focus();
  }, [clSdkDefaultUiOptions?.autoFocusChatInput]);
  const [unreadDividerElement, unreadDividerElementRef] =
    useState<HTMLDivElement | null>(null);
  const scrollToUnread = useEventCallback<() => void>(
    function scrollToUnread() {
      if (!containerEl) return;
      if (allMessagesRead) {
        scrollToBottomFn();
        return;
      }
      setScrolledToBottom(false);
      resetScrollingTask("unread");
      if (allMessagesUnread) {
        containerEl.scrollTo({
          top: 0,
        });
        return;
      }
      if (!unreadDividerElement) return;
      containerEl.scrollTo({
        top: unreadDividerElement.offsetTop,
      });
    },
  );

  useEffect(() => {
    updateFirstUnreadMessage();
    /**
     * Waiting for firstUnreadMessage to be updated.
     */
    const timeout = setTimeout(scrollToUnread, 1);
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [scrollToUnread, updateFirstUnreadMessage]);
  return (
    <>
      <Messages
        ref={containerRef}
        contentProps={{
          ref: contentRef,
        }}
        messageItems={messageItems}
        unreadDividerProps={{
          ref: unreadDividerElementRef,
        }}
        onBottomInView={(inView) => {
          setScrolledToBottom(inView.inView);
        }}
        onMessageRead={props.onMessageRead}
      />
      <BottomPanel
        textAreaProps={{
          ref: textAreaRef,
        }}
        onSendingMessage={scrollToBottomFn}
      />
    </>
  );
};

export { Dialogue };
