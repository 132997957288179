import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { Opts } from "linkifyjs";
import { Link } from "@mui/material";
import Linkify from "linkify-react";
import { Bubble } from "./Bubble";

namespace TextMessage {
  export interface Props
    extends Overwrite<
      DistributiveOmit<Bubble.Props, "children">,
      {
        text: string;
      }
    > {}
}

const linkifyOptions: Opts = {
  render: {
    url: ({ attributes, content }) => {
      const { href, ...props } = attributes;
      return (
        <Link href={href} {...props} target="_blank" rel="noopener noreferrer">
          {content}
        </Link>
      );
    },
  },
};

const TextMessage: React.FC<TextMessage.Props> = ({ text, ...props }) => {
  return (
    <Bubble {...props}>
      <Linkify options={linkifyOptions}>{text}</Linkify>
    </Bubble>
  );
};

export { TextMessage };
