import { createInstance } from "i18next";
import { useMemo } from "react";
import { I18nContext } from "react-i18next";
import { useClSdkDefaultUiOptions } from "../context";
import { initI18n } from "./utils";

namespace I18nProvider {
  export interface Options<
    TI18nBackend = object,
    TLanguages extends Array<string> = initI18n.DefaultLanguages,
  > extends initI18n.Options<TI18nBackend, TLanguages> {}
  export interface Props {
    children: React.ReactNode;
  }
}

const I18nProvider: React.FC<I18nProvider.Props> = ({ children }) => {
  const clSdkDefaultUiOptionsContext = useClSdkDefaultUiOptions();
  const i18n = useMemo(() => {
    const i18n = createInstance();
    return initI18n(i18n, clSdkDefaultUiOptionsContext?.i18n);
  }, [clSdkDefaultUiOptionsContext?.i18n]);
  const contextValue = useMemo(
    () => ({ i18n, defaultNS: "translation" }),
    [i18n],
  );
  return <I18nContext value={contextValue}>{children}</I18nContext>;
};

export { I18nProvider };
