import type { AxiosInstance, CreateAxiosDefaults } from "axios";
import type { ClSdk } from "../../ClSdk";
import { parseJson } from "@crescendolab/parse-json";
import { Zodios } from "@zodios/core";
import axios from "axios";
import { flow, isPrimitive } from "es-toolkit";
import { computed, type ReadableAtom } from "nanostores";
import { toIsoStringWithTz } from "../../internal/utils/toIsoStringWithTz";

import { camelcaseKeys } from "../../utils/camelcaseKeys";
import { snakecaseKeys } from "../../utils/snakecaseKeys";
import { apiAuth } from "./apiAuth";
import { apiChannelConfig } from "./apiChannelConfig";
import { apiMessage } from "./apiMessage";
import { apiSignedUrl } from "./apiSignedUrl";

/**
 * Serialize data to be sent to the server. Works for both search params and request body.
 *
 * - Do nothing for primitive data.
 * - Convert Date to ISO string with timezone offset deeply.
 * - Convert object keys to snake_case.
 */
function serializeData(data: unknown) {
  if (isPrimitive(data)) return data;
  if (data instanceof Date) return toIsoStringWithTz(data);
  return flow(() => data, toIsoStringWithTz.deeply, snakecaseKeys)();
}

const baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: {
    serialize: (params: Record<string, any>) => {
      return new URLSearchParams(snakecaseKeys(params)).toString();
    },
  },
  transformRequest: [
    serializeData,
    (data) => (data === undefined ? undefined : JSON.stringify(data)),
  ],
  transformResponse: [
    (data) => {
      if (!data) return data;
      const json = parseJson(data, {
        fallback: undefined,
      });
      return camelcaseKeys(json);
    },
  ],
} satisfies CreateAxiosDefaults;

namespace ClApi {
  export interface Options {
    clSdk: ClSdk;
  }
}

function generateClient(axiosInstance: AxiosInstance) {
  const auth = new Zodios(apiAuth, { axiosInstance });
  const message = new Zodios(apiMessage, { axiosInstance });
  const signedUrl = new Zodios(apiSignedUrl, { axiosInstance });
  const channelConfig = new Zodios(apiChannelConfig, { axiosInstance });
  return {
    auth,
    message,
    signedUrl,
    channelConfig,
  };
}

class ClApi {
  private clSdk: ClSdk;
  private $axios: ReadableAtom<AxiosInstance>;
  private $client: ReadableAtom<ReturnType<typeof generateClient>>;
  constructor(options: ClApi.Options) {
    this.clSdk = options.clSdk;
    this.$axios = computed(this.clSdk.env.$API_HOST, (apiHost) => {
      const axiosInstance = axios.create({
        ...baseConfig,
        baseURL: apiHost,
      });
      axiosInstance.interceptors.request.use((config) => {
        const token = this.clSdk.clAuth.$token.get();
        if (!config.headers.Authorization && token)
          config.headers.Authorization = `Bearer ${token}`;
        return config;
      });
      return axiosInstance;
    });
    this.$client = computed(this.$axios, generateClient);
  }
  get auth() {
    return this.$client.get().auth;
  }
  get channelConfig() {
    return this.$client.get().channelConfig;
  }
  get message() {
    return this.$client.get().message;
  }
  get signedUrl() {
    return this.$client.get().signedUrl;
  }
}

export { ClApi };
