import { z } from "zod";

export const Iso8601DatetimeSchema = z.pipeline(
  z.string().datetime(),
  z.coerce.date(),
);

export const AuthTokenSchema = z.string().brand("AuthToken");
export type AuthToken = z.infer<typeof AuthTokenSchema>;

export const AuthNonceSchema = z.string().brand("AuthNonce");
export type AuthNonce = z.infer<typeof AuthNonceSchema>;

export const AuthEdIdSchema = z.string().brand("AuthEdId");
export type AuthEdId = z.infer<typeof AuthEdIdSchema>;

export const SdkVersionSchema = z.string().brand("SdkVersion");
export type SdkVersion = z.infer<typeof SdkVersionSchema>;

export const ClWebChannelIdSchema = z.string().brand("ClWebChannelId");
export type ClWebChannelId = z.infer<typeof ClWebChannelIdSchema>;

export const SiteBoundUserUuidSchema = z.string().brand("SiteBoundUserUuid");
export type SiteBoundUserUuid = z.infer<typeof SiteBoundUserUuidSchema>;

export const DialogueIdSchema = z.string().brand("DialogueId");
export type DialogueId = z.infer<typeof DialogueIdSchema>;

export const DeviceIdSchema = z.string().brand("DeviceId");
export type DeviceId = z.infer<typeof DeviceIdSchema>;

export const UserInfoSchema = z.object({
  name: z.string().catch(""),
  coverUrl: z.string().catch(""),
});
export type UserInfo = z.infer<typeof UserInfoSchema>;

export const MessageSchema = z.object({
  id: z.string(),
  dialogueId: DialogueIdSchema,
  type: z.enum(["plain_text", "image", "video", "file"]),
  multimedia: z
    .object({
      url: z.string().url(),
      fileName: z.string(),
    })
    .nullable()
    .catch(null),
  content: z.string(),
  createdAt: Iso8601DatetimeSchema.nullable().catch(null),
  readAt: Iso8601DatetimeSchema.nullable().catch(null),
  role: z.enum(["sender", "receiver"]),
});
export type Message = z.infer<typeof MessageSchema>;

export const SignedUrlSchema = z.object({
  multimediaUuid: z.string().brand("SignedUrlUuid"),
  signedUrl: z.string().url().brand("SignedUrl"),
});
export type SignedUrl = z.infer<typeof SignedUrlSchema>;

export const ChannelStatusSchema = z.enum(["connected", "disconnected"]);
export type ChannelStatus = z.infer<typeof ChannelStatusSchema>;

export const ChannelConfigSchema = (() => {
  const ObjSchema = z.object({
    webTrackingEnabled: z.boolean().catch(false),
    webChatModuleEnabled: z.boolean().catch(false),
    status: ChannelStatusSchema.catch("disconnected"),
  });
  return ObjSchema.catch({
    webTrackingEnabled: ObjSchema.shape.webTrackingEnabled.parse(undefined),
    webChatModuleEnabled: ObjSchema.shape.webChatModuleEnabled.parse(undefined),
    status: ObjSchema.shape.status.parse(undefined),
  });
})();
export type ChannelConfig = z.infer<typeof ChannelConfigSchema>;
