import type { ClSdk } from "../../ClSdk";
import { atom } from "nanostores";
import { ClUploadTask } from "./ClUploadTask";

namespace ClUpload {
  export interface Options {
    clSdk: ClSdk;
  }
  export interface UploadOptions {
    file: File;
    abortController?: AbortController;
  }
}

class ClUpload {
  private clSdk: ClSdk;
  $tasks = atom<Array<ClUploadTask>>([]);
  constructor(options: ClUpload.Options) {
    this.clSdk = options.clSdk;
  }
  upload(options: ClUpload.UploadOptions) {
    const task = new ClUploadTask({
      clSdk: this.clSdk,
      file: options.file,
      ...(!options.abortController
        ? null
        : {
            abortController: options.abortController,
          }),
    });
    const clearTask = () => {
      this.$tasks.set(this.$tasks.get().filter((t) => t !== task));
      task.destroy();
    };
    this.$tasks.set([...this.$tasks.get(), task]);
    task.execution.promise.then(clearTask, clearTask);
    return task;
  }
  destroy() {
    const tasks = this.$tasks.get();
    tasks.forEach((task) => task.destroy());
  }
}

export { ClUpload };
