import type {
  DistributiveOmit,
  OverridableComponent,
  Overwrite,
} from "@mui/types";
import type { Dialogue } from "../../../../model";
import type { SxPropsRecord } from "../../../../types/SxPropsRecord";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  cardHeaderClasses,
  type CardProps,
  type CardTypeMap,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClButton } from "../../../../components/ClButton";
import { iconUrls } from "../../../../icons";
import { defuSx } from "../../../../theme/defuSx";
import { StartDialogue } from "./StartDialogue";

const sxPropsRecord = {
  card: {
    padding: "12px",
    gap: "4px",
  },
  cardHeader: {
    padding: "0",
    [`& .${cardHeaderClasses.avatar}`]: {
      marginRight: "8px",
    },
  },
  avatar: {
    width: "36px",
    height: "36px",
  },
  cardContent: {
    padding: "4px",
    top: "0",
  },
  cardActions: {
    padding: "0",
    flexDirection: "column",
    gap: "4px",
  },
  startButton: {
    marginTop: "8px",
  },
} satisfies SxPropsRecord;

namespace Start {
  export interface OwnType {
    onStartClick?: () => void;
    onSelectDialogue?: (dialogue: Dialogue) => void;
    dialogues?: Array<Dialogue>;
  }
  export type TypeMap<
    AdditionalProps = object,
    RootComponent extends React.ElementType = "div",
  > = CardTypeMap<Overwrite<AdditionalProps, OwnType>, RootComponent>;
  export type Props<
    RootComponent extends React.ElementType = TypeMap["defaultComponent"],
    AdditionalProps = object,
  > = Overwrite<
    DistributiveOmit<CardProps<RootComponent, AdditionalProps>, "children">,
    OwnType
  >;
  export type Type = OverridableComponent<TypeMap>;
}

const Start: Start.Type = <
  RootComponent extends React.ElementType = Start.TypeMap["defaultComponent"],
  AdditionalProps = object,
>({
  dialogues,
  onStartClick,
  onSelectDialogue,
  ...props
}: Start.Props<RootComponent, AdditionalProps>) => {
  const { t } = useTranslation();
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.card), [props.sx]);
  const availableDialogues = useMemo(
    () =>
      dialogues?.flatMap((dialogue) => {
        if (dialogue.messages.length === 0) return [];
        return [dialogue];
      }) ?? [],
    [dialogues],
  );
  return (
    <Card {...props} sx={sx}>
      <CardHeader
        sx={sxPropsRecord.cardHeader}
        avatar={<Avatar src={iconUrls.Avatar} />}
        title={
          <Typography variant="h3">{t("chat.startScreen.title")}</Typography>
        }
      />
      <CardContent sx={sxPropsRecord.cardContent}>
        <Typography>{t("chat.startScreen.description")}</Typography>
      </CardContent>
      <CardActions sx={sxPropsRecord.cardActions}>
        {availableDialogues.length > 0 ? (
          availableDialogues.map((dialogue) => (
            <StartDialogue
              key={dialogue.id}
              message={dialogue.messages[dialogue.messages.length - 1]!}
              onClick={() => onSelectDialogue?.(dialogue)}
            />
          ))
        ) : (
          <ClButton onClick={onStartClick} variant="contained" fullWidth>
            {t("chat.startScreen.startButton")}
          </ClButton>
        )}
      </CardActions>
    </Card>
  );
};

export { Start };
