import type { z } from "zod";
import type { ClWebChannelIdSchema } from "./model";

import type { ClPlugin } from "./plugins/utils/ClPlugin";
import { flow } from "es-toolkit";
import { atom, computed, type ReadableAtom } from "nanostores";
import { env } from "./env";
import { ClApi } from "./modules/ClApi";
import { ClAuth } from "./modules/ClAuth";
import { ClChannel } from "./modules/ClChannel";
import { ClChat } from "./modules/ClChat";
import { ClDeviceId } from "./modules/ClDeviceId";
import { ClRe } from "./modules/ClRe";
import { ClUpload } from "./modules/ClUpload";
import { ClUploadExecution } from "./modules/ClUpload/ClUploadExecution";
import { ClWs } from "./modules/ClWs";
import { isWebCrawler } from "./utils/isWebCrawlers";

namespace ClSdk {
  export interface Features {
    clDebug: boolean;
    clRe: boolean;
    clChat: boolean;
    clDeviceId: boolean | ClDeviceId.FeatureOptions;
  }
  export interface Overrides {
    env?: Partial<Omit<typeof env, "SDK_VERSION">>;
    features?: Partial<Features>;
  }
  export interface Options {
    clWebChannelId: z.input<typeof ClWebChannelIdSchema>;
    clPlugins?: Array<ReturnType<ClPlugin.Factory>>;
    overrides?: Overrides;
  }
}

const defaultFeatures: ClSdk.Features = {
  clDebug: false,
  clRe: false,
  clChat: false,
  clDeviceId: true,
};

class ClSdk {
  static version = env.SDK_VERSION;
  atoms = {
    $features: atom<Partial<ClSdk.Features> | null>(null),
    $overrides: atom<ClSdk.Overrides>({}),
    $plugins: atom<Array<ClPlugin>>([]),
  };
  clWebChannelId: z.input<typeof ClWebChannelIdSchema>;
  clApi: ClApi;
  clWs: ClWs;
  clUploadExecution: ClUploadExecution;
  clUpload: ClUpload;
  clChannel: ClChannel;
  clAuth: ClAuth;
  clDeviceId: ClDeviceId;
  clRe: ClRe;
  clChat: ClChat;
  private destroyTasks: Array<() => void> = [];

  private $env = computed(this.atoms.$overrides, (overrides) => {
    const mergedEnv: NonNullable<ClSdk.Overrides["env"]> = {
      ...env,
      ...overrides.env,
    };
    if ("SDK_VERSION" in mergedEnv) {
      delete (mergedEnv as Partial<typeof env>).SDK_VERSION;
    }
    return mergedEnv;
  });

  // @ts-expect-error -- TypeScript handles this poorly.
  env: {
    [TKey in keyof typeof env as `$${TKey}`]: ReadableAtom<(typeof env)[TKey]>;
  } = Object.fromEntries(
    Object.entries(env).map(([key]) => [
      `$${key}`,
      computed(
        this.$env,
        (env) =>
          // @ts-expect-error -- TypeScript handles this poorly.
          env[key],
      ),
    ]),
  );

  private $features = computed(
    [this.atoms.$overrides, this.atoms.$features, this.atoms.$plugins],
    (overrides, features, plugins) => {
      const processedFeatures: Required<NonNullable<typeof features>> = flow(
        () => features,
        ...plugins.flatMap((plugin) =>
          !plugin["~hooks"]?.beforeApplyFeatures
            ? []
            : [plugin["~hooks"].beforeApplyFeatures],
        ),
      )();
      return {
        ...defaultFeatures,
        /**
         * Features from the API.
         */
        ...processedFeatures,
        /**
         * If the user is a web crawler, disable the `clRe` feature.
         */
        ...(!isWebCrawler
          ? null
          : {
              clRe: false,
            }),
        /**
         * Allow overriding the features.
         */
        ...overrides.features,
      } satisfies ClSdk.Features;
    },
  );

  // @ts-expect-error -- TypeScript handles this poorly.
  features: {
    [TKey in keyof ClSdk.Features as `$${TKey}`]: ReadableAtom<
      ClSdk.Features[TKey]
    >;
  } = Object.fromEntries(
    Object.entries(defaultFeatures).map(([key]) => [
      `$${key}`,
      computed(
        this.$features,
        (features) =>
          // @ts-expect-error -- TypeScript handles this poorly.
          features[key],
      ),
    ]),
  );

  constructor(options: ClSdk.Options) {
    this.clWebChannelId = options.clWebChannelId;
    this.atoms.$overrides.set(options.overrides ?? {});
    this.clApi = new ClApi({ clSdk: this });
    this.clAuth = new ClAuth({ clSdk: this });
    this.clDeviceId = new ClDeviceId({ clSdk: this });
    this.clWs = new ClWs({ clSdk: this });
    this.clUploadExecution = new ClUploadExecution({ clSdk: this });
    this.clUpload = new ClUpload({ clSdk: this });
    this.clChannel = new ClChannel({ clSdk: this });
    this.clRe = new ClRe({ clSdk: this });
    this.clChat = new ClChat({ clSdk: this });
    if (options.clPlugins && options.clPlugins.length > 0) {
      this.atoms.$plugins.set([
        ...this.atoms.$plugins.get(),
        ...options.clPlugins.map((pluginFactory) =>
          pluginFactory({ clSdk: this }),
        ),
      ]);
    }
    this.clRe.init();
    for (const plugin of this.atoms.$plugins.get()) {
      plugin["~init"]?.();
    }
    this.destroyTasks.push(
      // Destroy all plugins when they are removed.
      this.atoms.$plugins.subscribe((plugins, oldPlugins) => {
        if (!oldPlugins) return;
        const removedPlugins = oldPlugins.filter(
          (oldPlugin) => !plugins.includes(oldPlugin),
        );
        removedPlugins.forEach((plugin) => plugin.destroy());
      }),
    );
  }

  destroy = () => {
    this.atoms.$plugins.get().forEach((plugin) => plugin.destroy());
    this.clChat.destroy();
    this.clRe.destroy();
    this.clChannel.destroy();
    this.clAuth.destroy();
    this.clWs.destroy();
    this.destroyTasks.forEach((task) => task());
  };
}

export { ClSdk };
