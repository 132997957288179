import type { FabProps, FabTypeMap } from "@mui/material";
import { Fab } from "@mui/material";

namespace ClFab {
  export type Props<
    RootComponent extends React.ElementType = FabTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = FabProps<RootComponent, AdditionalProps>;
  export type Type = typeof Fab;
}

const ClFab: ClFab.Type = <
  RootComponent extends React.ElementType = FabTypeMap["defaultComponent"],
  AdditionalProps = object,
>(
  props: ClFab.Props<RootComponent, AdditionalProps>,
) => {
  return <Fab {...props} />;
};

export { ClFab };
