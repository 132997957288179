import type { AnyFunction } from "@chatbotgang/web-sdk-core/types/AnyFunction";
import type { SxProps } from "../types/SxProps";

type CssVarRecord = Record<`--${string}`, string>;

/**
 * Compatible with sx props but only for css vars.
 */
type CssVarSxProps =
  | CssVarRecord
  | ((...args: Parameters<Extract<SxProps, AnyFunction>>) => CssVarRecord);

const defineCssVarUtils = <TSxProps extends CssVarSxProps>(props: TSxProps) => {
  type Key = keyof (TSxProps extends AnyFunction
    ? ReturnType<TSxProps>
    : TSxProps) &
    keyof CssVarRecord;
  function getKey(key: Key) {
    return key;
  }
  function getValue(key: Key) {
    return `var(${key})`;
  }
  return {
    props,
    getKey,
    getValue,
  };
};

export { defineCssVarUtils };
