import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { SxPropsRecord } from "../types/SxPropsRecord";
import { Box, type BoxProps } from "@mui/material";

import {
  MediaPlayer,
  type MediaPlayerProps,
  MediaProvider,
} from "@vidstack/react";

import {
  PlyrLayout,
  plyrLayoutIcons,
} from "@vidstack/react/player/layouts/plyr";
import React, { useMemo } from "react";
import { defuSx } from "../theme/defuSx";

const sxPropsRecord = {
  container: {
    "&&, *": {
      minWidth: "auto",
    },
  },
} satisfies SxPropsRecord;

namespace VidStack {
  export type Props = Overwrite<
    DistributiveOmit<MediaPlayerProps, "children">,
    {
      containerProps?: DistributiveOmit<BoxProps, "children" | "component">;
    }
  >;
}

const VidStackInternal: React.FC<VidStack.Props> = ({
  containerProps,
  ...props
}) => {
  const containerSx = useMemo(
    () => defuSx(containerProps?.sx, sxPropsRecord.container),
    [containerProps?.sx],
  );
  return (
    <Box {...containerProps} sx={containerSx}>
      <MediaPlayer {...props}>
        <MediaProvider />
        <PlyrLayout icons={plyrLayoutIcons} />
      </MediaPlayer>
    </Box>
  );
};

const VidStack = Object.assign(VidStackInternal, {
  sxPropsRecord,
});

export { VidStack };
