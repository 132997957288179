import type { initI18n } from "@chatbotgang/web-sdk-default-ui/i18n/utils";
import type { ClWidget } from ".";

import { createContext, useContext } from "react";

const ClWidgetPropsContextInternal = createContext<ClWidget.Props<
  object,
  Array<string>
> | null>(null);

function useClWidgetProps<
  TI18nBackend = object,
  TLanguages extends Array<string> = initI18n.DefaultLanguages,
>(): ClWidget.Props<TI18nBackend, TLanguages> {
  const props = useContext(ClWidgetPropsContextInternal);

  if (!props) {
    throw new Error(`useOptions must be used within a OptionsContext`);
  }

  return props satisfies ClWidget.Props<
    object,
    Array<string>
  > as ClWidget.Props<TI18nBackend, TLanguages>;
}

const ClWidgetPropsContext = Object.assign(ClWidgetPropsContextInternal, {
  useClWidgetProps,
});

export { ClWidgetPropsContext };
