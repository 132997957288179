import { useMemo } from "react";
import { useTranslation } from "react-i18next";

namespace MessageDateTime {
  export interface Props {
    date: Date | null;
  }
}

const MessageDateTime: React.FC<MessageDateTime.Props> = ({ date }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const str = useMemo(
    () =>
      !date || Number.isNaN(date?.getTime())
        ? "???"
        : Intl.DateTimeFormat(language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }).format(date),
    [date, language],
  );
  return str;
};

export { MessageDateTime };
