import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  AuthEdIdSchema,
  AuthNonceSchema,
  AuthTokenSchema,
  ClWebChannelIdSchema,
  DialogueIdSchema,
  SdkVersionSchema,
  SiteBoundUserUuidSchema,
} from "../../model";

const apiAuth = makeApi([
  {
    alias: "authenticate",
    method: "post",
    path: "/api/v1/authenticate",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          clWebChannelId: ClWebChannelIdSchema,
          sdkVersion: SdkVersionSchema,
          siteBoundUserUuid: SiteBoundUserUuidSchema,
        }),
      },
    ],
    response: z.object({
      token: AuthTokenSchema,
      dialogueId: DialogueIdSchema,
      nonce: AuthNonceSchema.catch(AuthNonceSchema.parse("")),
      edId: AuthEdIdSchema.catch(AuthEdIdSchema.parse("")),
    }),
  },
]);

export { apiAuth };
