import mitt from "mitt";

// eslint-disable-next-line ts/consistent-type-definitions -- Type is better inferred than interface here.
type PageEvents = {
  pageView: void;
  popState: void;
  pushState: void;
  replaceState: void;
  hashChange: void;
};

const eventEmitter = mitt<PageEvents>();

// Pageview tracking for single page applications
let lastPage: typeof location.href;

function page() {
  const loc = location.href;
  if (lastPage === loc) return;
  lastPage = loc;
  eventEmitter.emit("pageView");
}

window.addEventListener("hashchange", () => {
  eventEmitter.emit("hashChange");
});
eventEmitter.on("hashChange", page);

window.addEventListener("popstate", () => {
  eventEmitter.emit("popState");
});
eventEmitter.on("popState", page);

const hist = window.history;
if (hist.pushState) {
  const originalPushState = hist.pushState;
  hist.pushState = function (...args) {
    originalPushState.apply(hist, args);
    eventEmitter.emit("pushState");
  };
}
eventEmitter.on("pushState", page);

if (hist.replaceState) {
  const originalReplaceState = hist.replaceState;
  hist.replaceState = function (...args) {
    originalReplaceState.apply(hist, args);
    eventEmitter.emit("replaceState");
  };
}
eventEmitter.on("replaceState", page);

page();

export { eventEmitter };
