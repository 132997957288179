import { makeApi } from "@zodios/core";
import { z } from "zod";

import { MessageSchema, SignedUrlSchema } from "../../model";

const apiMessage = makeApi([
  {
    alias: "getList",
    method: "get",
    path: "/api/v1/messages",
    response: z
      .array(MessageSchema.nullable().catch(null))
      .transform((data) => data.flatMap((item) => (item ? [item] : []))),
  },
  {
    alias: "send",
    method: "post",
    path: "/api/v1/messages",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          dialogueId: z.string(),
          type: z.enum(["plain_text", "image", "video", "file"]),
          content: z.string().optional(),
          multimediaId: SignedUrlSchema.shape.multimediaUuid.optional(),
        }),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: "read",
    method: "post",
    path: "/api/v1/dialogues/read_status_update",
    response: z.unknown(),
  },
]);

export { apiMessage };
