import type { MessageSchema } from "@chatbotgang/web-sdk-core/model";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { z } from "zod";
import type { MessageItem } from "../../../../../../model";

import type { SxPropsRecord } from "../../../../../../types/SxPropsRecord";

import { Box, type BoxProps } from "@mui/material";
import useEventCallback from "@mui/utils/useEventCallback";
import clsx from "clsx";

import { secondsToMilliseconds } from "date-fns";
import React, { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { defuSx } from "../../../../../../theme/defuSx";
import { getFontSize, getToken } from "../../../../../../theme/tokens";
import { mergeRefs } from "../../../../../../utils/mergeRefs";
import { AutoMessage } from "./AutoMessage";
import { Bubble } from "./Bubble";
import { MessageDateTime } from "./MessageDateTime";
import { messageStyleUtils } from "./messageStyleUtils";

const sxPropsRecord = {
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    color: getToken("--cl-widget-color-static-fg-note"),
    fontSize: getFontSize(12),
    gap: "4px",
    ...messageStyleUtils.cssVarUtils.props,
    "&>*": {
      marginRight: "auto",
    },
    a: {
      textDecorationColor: "currentcolor",
    },
    [`&.${messageStyleUtils.classNameRecord.byMe}`]: {
      "&>*": {
        [messageStyleUtils.cssVarUtils.getKey("--cl-widget-bubble-bg-color")]:
          getToken("--cl-widget-color-static-bg-page"),
        [messageStyleUtils.cssVarUtils.getKey("--cl-widget-bubble-fg-color")]:
          getToken("--cl-widget-color-static-fg-body"),
        marginRight: "0",
        marginLeft: "auto",
      },
    },
    [`&:not(.${messageStyleUtils.classNameRecord.byMe})`]: {
      [`.${Bubble.classNameRecord.root}`]: {
        a: {
          color: "currentColor",
        },
      },
    },
  },
  [`.${messageStyleUtils.classNameRecord.sentAt}`]: {
    color: getToken("--cl-widget-color-static-fg-note"),
    fontSize: getToken("--cl-widget-font-size-note"),
  },
} satisfies SxPropsRecord;

const MessageContent: React.FC<{
  message: z.infer<typeof MessageSchema>;
}> = ({ message }) => {
  return (
    <AutoMessage
      text={message.multimedia?.fileName || message.content}
      url={message.multimedia?.url ?? ""}
    />
  );
};

namespace Message {
  export interface Props
    extends Overwrite<
      DistributiveOmit<BoxProps, "children" | "component">,
      {
        messageItem: MessageItem;
        onInView?: (options: {
          messageItem: MessageItem;
          inView: ReturnType<typeof useInView>;
        }) => void;
        onMessageRead?: (message: {
          messageItem: MessageItem;
          inView: ReturnType<typeof useInView>;
        }) => void;
      }
    > {}
}

const MessageInternal: React.FC<Message.Props> = ({
  messageItem,
  onMessageRead,
  ...props
}) => {
  const { message } = messageItem;
  const sentByAgent = message.role === "receiver";
  const divInView = useInView();
  const onReadHandler = useEventCallback(() => {
    /**
     * If the message has already been read, do nothing.
     */
    if (messageItem.message.readAt) return;
    onMessageRead?.({
      messageItem,
      inView: divInView,
    });
  });
  const read = Boolean(message.readAt);
  const reading = Boolean(divInView.inView && !read);
  useEffect(() => {
    if (!reading) return;
    const timeout = setTimeout(onReadHandler, secondsToMilliseconds(0.5));
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [onReadHandler, reading]);

  const rootClassName = useMemo(
    () =>
      clsx(
        props.className,
        messageStyleUtils.classNameRecord.root,
        !sentByAgent && messageStyleUtils.classNameRecord.byMe,
      ),
    [props.className, sentByAgent],
  );
  const rootSx = useMemo(
    () => defuSx(props.sx, sxPropsRecord.root),
    [props.sx],
  );
  const rootRef = useMemo(
    () => mergeRefs(props.ref, divInView.ref),
    [props.ref, divInView.ref],
  );

  return (
    <Box {...props} className={rootClassName} sx={rootSx} ref={rootRef}>
      <MessageContent message={message} />
      <Box className={messageStyleUtils.classNameRecord.sentAt}>
        <MessageDateTime date={message.createdAt} />
      </Box>
    </Box>
  );
};

const Message = Object.assign(MessageInternal, {
  sxPropsRecord,
});

export { Message };
