import type { SxPropsRecord } from "../../../../../../types/SxPropsRecord";
import { type ComponentProps, useMemo } from "react";
import { VidStack } from "../../../../../../components/VidStack";
import { defuSx } from "../../../../../../theme/defuSx";
import { messageStyleUtils } from "./messageStyleUtils";

const sxPropsRecord = {
  container: {
    "&&": {
      width: `calc(100% - ${messageStyleUtils.cssVarUtils.getValue("--cl-widget-sender-margin")})`,
      minWidth: "20px",
      maxHeight: "50%",
      borderRadius: "2px",
      ".plyr:not(.plyr--fullscreen-active)": {
        "--plyr-control-spacing": "6px",
        "--plyr-range-track-height": "4px",
        "--plyr-range-thumb-height": "8px",
        button: {
          display: "flex",
          minWidth: 0,
          minHeight: 0,
          alignItems: "center",
          justifyContent: "center",
          aspectRatio: 1,
          svg: { width: 14, aspectRatio: 1 },
        },
        ".plyr__progress": {
          marginRight: "calc(var(--plyr-range-thumb-height) * 2)",
        },
        ".plyr__time, .plyr__volume, .plyr__control[data-plyr='settings'], .plyr__control[data-plyr='pip']":
          { display: "none" },
      },
    },
  },
} satisfies SxPropsRecord;

namespace VidStackMessage {
  export interface Props extends VidStack.Props {}
}

const VidStackMessage: React.FC<VidStackMessage.Props> = ({
  className,
  ...props
}) => {
  const containerSx = useMemo(
    () => defuSx(props.containerProps?.sx, sxPropsRecord.container),
    [props.containerProps?.sx],
  );
  const containerProps: ComponentProps<typeof VidStack>["containerProps"] =
    useMemo(
      () => ({
        ...props.containerProps,
        sx: containerSx,
      }),
      [containerSx, props.containerProps],
    );
  return <VidStack {...props} containerProps={containerProps} />;
};

export { VidStackMessage };
