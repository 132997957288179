async function isVideoFile(file: File): Promise<boolean> {
  const video = document.createElement("video");
  function cleanup() {
    URL.revokeObjectURL(video.src);
    video.remove();
  }
  video.src = URL.createObjectURL(file);
  return new Promise((resolve) => {
    video.onloadedmetadata = () => {
      cleanup();
      resolve(file.type.startsWith("video/"));
    };
    video.onerror = () => {
      cleanup();
      resolve(false);
    };
  });
}

export { isVideoFile };
