import {
  type DialogueId,
  DialogueIdSchema,
} from "@chatbotgang/web-sdk-core/model";
import { generateKey } from "@chatbotgang/web-sdk-core/utils/generateKey";
import { persistentAtom, type PersistentEncoder } from "@nanostores/persistent";

const subPrefix = "DEFAULT_PRESET_" as const;

function generateDefaultPresetKey<TInput extends string>(input: TInput) {
  return generateKey(`${subPrefix}${input}`);
}

const booleanPersistentEncoder: PersistentEncoder<boolean> = {
  encode: (b) => (!b ? "" : "1"),
  decode: (s) => Boolean(s),
};

const KEY_CHAT_OPENED = generateDefaultPresetKey("CHAT_OPENED");
const KEY_CHAT_SELECTED_DIALOGUE_ID = generateDefaultPresetKey(
  "CHAT_SELECTED_DIALOGUE_ID",
);

const chatStores = (() => {
  const key = KEY_CHAT_OPENED;
  const $opened = persistentAtom<boolean>(key, false, booleanPersistentEncoder);
  const open = () => {
    $opened.set(true);
  };
  const close = () => {
    $opened.set(false);
  };

  const $selectedDialogueId = persistentAtom<DialogueId | null>(
    KEY_CHAT_SELECTED_DIALOGUE_ID,
    null,
    {
      encode: (v) => v ?? "",
      decode: (s) => DialogueIdSchema.nullable().catch(null).parse(s),
    },
  );
  const setSelectedDialogueId = (dialogueId: DialogueId) => {
    $selectedDialogueId.set(dialogueId);
  };
  const clearSelectedDialogueId = () => {
    $selectedDialogueId.set(null);
  };
  return {
    $opened,
    open,
    close,
    $selectedDialogueId,
    setSelectedDialogueId,
    clearSelectedDialogueId,
  };
})();

export { chatStores };
