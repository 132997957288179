// cspell:words flac
import { promiseWithResolvers } from "@chatbotgang/web-sdk-core/utils/promiseWithResolvers";
import {
  QueryClientProvider,
  type QueryKey,
  useQuery,
} from "@tanstack/react-query";
import {
  AUDIO_EXTENSIONS,
  AUDIO_TYPES,
  VIDEO_EXTENSIONS,
  VIDEO_TYPES,
} from "@vidstack/react";
import { nanoid } from "nanoid";
import { useMemo, useState } from "react";
import { z } from "zod";
import { queryClient } from "../../../../../../queryClient";
import { FileMessage } from "./FileMessage";
import { ImgMessage } from "./ImgMessage";
import { TextMessage } from "./TextMessage";
import { VidStackMessage } from "./VidStackMessage";

namespace AutoMessage {
  export interface Props {
    text: string;
    url: string;
  }
}

const seed = nanoid();
const fetchMetaKey = `fetch-meta-${seed}` as const;

const generateQueryKey = (url: string) =>
  [
    {
      [fetchMetaKey]: fetchMetaKey,
      url,
    },
  ] as const satisfies QueryKey;

async function isImage(src: string) {
  const img = document.createElement("img");
  img.src = src;
  const resolver = promiseWithResolvers<boolean>();
  img.onload = () => resolver.resolve(true);
  img.onerror = () => resolver.resolve(false);
  return resolver.promise;
}

const AutoMessageInternal: React.FC<AutoMessage.Props> = ({ text, url }) => {
  const [loadError, setLoadError] = useState<unknown>(null);
  const isValidUrl = useMemo(
    () => z.string().url().safeParse(url).success,
    [url],
  );
  const queryKey = useMemo(() => generateQueryKey(url), [url]);
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (VIDEO_EXTENSIONS.test(url) || AUDIO_EXTENSIONS.test(url)) {
        return {
          contentType: "",
          type: "vidStack",
        } as const;
      }
      if (await isImage(url)) {
        return {
          type: "image",
        } as const;
      }
      const contentType = await (async () => {
        try {
          const response = await fetch(url, {
            method: "HEAD",
            signal,
          });
          signal.throwIfAborted();
          return response.headers.get("content-type");
        } catch (e) {
          // Fallback to GET request if HEAD request fails
          const response = await fetch(url, {
            signal,
          });
          signal.throwIfAborted();
          const contentType = response.headers.get("content-type");
          if (contentType) {
            return contentType;
          }
          const blob = await response.blob();
          return blob.type;
        }
      })();
      if (
        contentType &&
        (AUDIO_TYPES.has(contentType) || VIDEO_TYPES.has(contentType))
      ) {
        return {
          contentType,
          type: "vidStack",
        } as const;
      }
      if (contentType?.startsWith("image/")) {
        return {
          type: "image",
        } as const;
      }
      return {
        type: "file",
      } as const;
    },
    enabled: isValidUrl,
  });
  if (!isValidUrl) {
    return <TextMessage text={text} />;
  }
  if (!loadError) {
    if (query.data?.type === "image") {
      return (
        <ImgMessage src={url} alt={text} onError={(e) => setLoadError(e)} />
      );
    }
    if (query.data?.type === "vidStack") {
      return (
        <VidStackMessage
          title={text}
          src={
            "contentType" in query.data
              ? {
                  src: url,
                  type: query.data.contentType as any,
                }
              : url
          }
          onError={(e) => setLoadError(e)}
        />
      );
    }
  }
  return <FileMessage filename={text} url={url} />;
};

const AutoMessage: typeof AutoMessageInternal = (props) => {
  const url = props.url ?? "";
  return (
    <QueryClientProvider client={queryClient} key={url}>
      <AutoMessageInternal {...props} />
    </QueryClientProvider>
  );
};

export { AutoMessage };
