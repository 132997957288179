import type { Tagged } from "type-fest";
// copied from Zeffiroso
import { format } from "date-fns/fp";
import { mapValues } from "es-toolkit";

type IsoStringWithTz = Tagged<string, "IsoStringWithTz">;
const toIsoStringWithTz = format("yyyy-MM-dd'T'HH:mm:ss.SSSxxx") as (
  date: Date,
) => IsoStringWithTz;

type ToIsoStringWithTzDeeply<T> = T extends Date
  ? IsoStringWithTz
  : T extends object
    ? { [K in keyof T]: ToIsoStringWithTzDeeply<T[K]> }
    : T;

const toIsoStringWithTzDeeply = <T>(data: T): ToIsoStringWithTzDeeply<T> => {
  if (data instanceof Date)
    return toIsoStringWithTz(data) as ToIsoStringWithTzDeeply<T>;
  if (!data || typeof data !== "object")
    return data as ToIsoStringWithTzDeeply<T>;
  if (Array.isArray(data))
    return data.map(toIsoStringWithTzDeeply) as ToIsoStringWithTzDeeply<T>;
  return mapValues(data, toIsoStringWithTzDeeply) as ToIsoStringWithTzDeeply<T>;
};

/**
 * Converts a date to an ISO string with timezone offset.
 *
 * ```ts
 * const date = new Date("2017-05-17T00:00:00.000Z");
 * const isoString = toIsoStringWithTz(date);
 * // "2017-05-17T00:00:00.000+00:00" in UTC timezone
 * // "2017-05-17T08:00:00.000+08:00" in Asia/Taipei timezone
 * ```
 *
 * Converts deeply nested objects to ISO string with timezone offset.
 *
 * ```ts
 * toIsoStringWithTz.deeply("foo"); // "foo"
 * toIsoStringWithTz.deeply(new Date("2017-05-17T00:00:00.000Z")); // "2017-05-17T00:00:00.000+00:00"
 * toIsoStringWithTz.deeply({ foo: new Date("2017-05-17T00:00:00.000Z") }); // { foo: "2017-05-17T00:00:00.000+00:00" }
 * ```
 */
const api = Object.assign(toIsoStringWithTz, {
  deeply: toIsoStringWithTzDeeply,
});

export { api as toIsoStringWithTz };
export type { IsoStringWithTz, ToIsoStringWithTzDeeply };
