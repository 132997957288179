import type { DialogueId } from "@chatbotgang/web-sdk-core/model";

import type { ClDialogue } from "@chatbotgang/web-sdk-core/modules/ClChat/ClDialogue";
import type { Dialogue as DialogueType } from "../../../model";
import type { SxPropsRecord } from "../../../types/SxPropsRecord";
import type { TargetAndTransitionRecord } from "../../../types/TargetAndTransitionRecord";
import type { Messages } from "./Dialogue/Messages";
import { Box } from "@mui/material";
import { noop } from "es-toolkit/compat";
import { AnimatePresence, motion } from "motion/react";
import { useMemo } from "react";
import { useVerticalReverse } from "../../../context";
import { ChatCard } from "./ChatCard";
import { Dialogue } from "./Dialogue";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Start } from "./Start";

const sxPropsRecord = {
  startWrapper: {
    paddingInline: "16px",
    translate: "0 -20px",
  },
} satisfies SxPropsRecord;

namespace ClChatMain {
  export interface Props {
    opened?: boolean;
    onClose?: () => void;
    onUnselectDialogue?: () => void;
    selectedDialogueId?: DialogueId;
    onStartDialogue?: Start.Props["onStartClick"];
    onSelectDialogue?: Start.Props["onSelectDialogue"];
    onSendMessage?: (message: ClDialogue.SendMessageOptions) => void;
    dialogues?: Array<DialogueType>;
    onMessageRead?: Messages.Props["onMessageRead"];
  }
  export type ScrollingTask = "bottom" | "unread";
}

const ClChatMain: React.FC<ClChatMain.Props> = (props) => {
  const selectedDialogue = useMemo<null | DialogueType>(() => {
    if (!props.selectedDialogueId) return null;
    return (
      props.dialogues?.find((d) => d.id === props.selectedDialogueId) ?? null
    );
  }, [props.dialogues, props.selectedDialogueId]);
  const started = useMemo(() => Boolean(selectedDialogue), [selectedDialogue]);
  const verticalReverse = useVerticalReverse();
  const targetAndTransitionRecord = useMemo(
    () =>
      ({
        startClosed: {
          opacity: 0,
          y: verticalReverse ? "-20px" : "20px",
        },
        startOpened: {
          opacity: 1,
          y: "0",
        },
      }) satisfies TargetAndTransitionRecord,
    [verticalReverse],
  );
  return (
    <ChatCard
      component={motion.div}
      layout
      opened={props.opened ?? false}
      started={started ?? false}
    >
      <Header
        closeButtonProps={{ onClick: props?.onClose }}
        backButtonProps={{ onClick: props?.onUnselectDialogue }}
        started={started ?? false}
      />
      <AnimatePresence>
        {selectedDialogue ? (
          <Dialogue
            key={selectedDialogue.id}
            messages={selectedDialogue.messages}
            onMessageRead={props.onMessageRead}
          />
        ) : (
          <Box
            component={motion.div}
            sx={sxPropsRecord.startWrapper}
            initial={targetAndTransitionRecord.startClosed}
            animate={targetAndTransitionRecord.startOpened}
            exit={targetAndTransitionRecord.startClosed}
          >
            <Start
              dialogues={props.dialogues ?? []}
              onStartClick={props.onStartDialogue ?? noop}
              onSelectDialogue={props.onSelectDialogue ?? noop}
            />
          </Box>
        )}
      </AnimatePresence>
      <Footer />
    </ChatCard>
  );
};

export { ClChatMain };
