import type { ClSdk } from "../../ClSdk";

import { computed, type ReadableAtom } from "nanostores";
import { $dialogueId } from "../../store/dialogueIdStore";
import { ClDebug } from "../ClDebug";
import { ClDialogue } from "./ClDialogue";

namespace ClChat {
  export interface Options {
    clSdk: ClSdk;
  }
}

class ClChat {
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  private $enabled: ReadableAtom<boolean>;
  $dialogue: ReadableAtom<ClDialogue | null>;
  private destroyTasks: Array<() => void> = [];
  constructor(options: ClChat.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({ clSdk: this.clSdk, module: "ClChat" });
    this.$enabled = this.clSdk.features.$clChat;
    this.clDebug.debug("Initialized", {
      clSdk: this.clSdk,
      enabled: this.$enabled.get(),
    });
    this.$dialogue = computed(
      [this.$enabled, $dialogueId],
      (enabled, dialogueId) => {
        if (!enabled) return null;
        if (!dialogueId) return null;
        return new ClDialogue({
          clSdk: this.clSdk,
          dialogueId,
        });
      },
    );
    this.destroyTasks.push(
      this.$dialogue.subscribe((_dialogue, oldDialogue) => {
        if (oldDialogue) {
          oldDialogue.destroy();
        }
      }),
    );
  }
  destroyDialogue = (dialogue: ClDialogue) => {
    dialogue.destroy();
  };
  destroy = () => {
    const dialogue = this.$dialogue.get();
    if (dialogue) {
      dialogue.destroy();
    }
  };
}

export { ClChat };
