/* eslint-disable no-console -- Debugger */

import type { ReadableAtom } from "nanostores";
import type { ClSdk } from "../../ClSdk";

import chalk from "chalk";

namespace ClDebug {
  export interface Options {
    clSdk: ClSdk;
    module: string;
  }
}

class ClDebug {
  private clSdk: ClSdk;
  private $enabled: ReadableAtom<boolean>;
  private namespace: string;
  constructor(options: ClDebug.Options) {
    this.clSdk = options.clSdk;
    this.$enabled = this.clSdk.features.$clDebug;
    this.namespace = ["ClSdk", this.clSdk.clWebChannelId, options.module].join(
      ":",
    );
  }
  debug = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.debug(chalk.bgGray.black(`${this.namespace}:debug`), ...args);
  };
  log = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.log(chalk.bgWhite.black(`${this.namespace}:log`), ...args);
  };
  info = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.info(chalk.bgBlue.white(`${this.namespace}:info`), ...args);
  };
  warn = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.warn(chalk.bgYellow.black(`${this.namespace}:warn`), ...args);
  };
  error = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.error(chalk.bgRed.white(`${this.namespace}:error`), ...args);
  };
  trace = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.trace(chalk.bgMagenta.white(`${this.namespace}:trace`), ...args);
  };
  table = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.log(chalk.bgGreen.black(`${this.namespace}:table`));
    console.table(...args);
  };
  group = (...args: Array<any>) => {
    if (!this.$enabled.get()) return;
    console.group(chalk.bgCyan.black(`${this.namespace}:group`), ...args);
  };
  groupEnd = () => {
    console.groupEnd();
  };
}

export { ClDebug };
