import type { ExtractOverridableComponent } from "../types/ExtractOverridableComponent";
import {
  IconButton,
  type IconButtonProps,
  type IconButtonTypeMap,
} from "@mui/material";

namespace ClIconButton {
  export type Props<
    RootComponent extends
      React.ElementType = IconButtonTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = IconButtonProps<RootComponent, AdditionalProps>;
  export type Type = typeof IconButton;
}

const ClIconButtonInternal: ExtractOverridableComponent<typeof IconButton> = <
  RootComponent extends
    React.ElementType = IconButtonTypeMap["defaultComponent"],
  AdditionalProps = object,
>(
  props: ClIconButton.Props<RootComponent, AdditionalProps>,
) => {
  return <IconButton {...props} />;
};

const ClIconButton: ClIconButton.Type = Object.assign(
  ClIconButtonInternal,
  IconButton,
);

export { ClIconButton };
