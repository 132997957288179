import type { SxPropsRecord } from "../../../types/SxPropsRecord";
import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClIcon } from "../../../components/ClIcon";
import { getToken } from "../../../theme/tokens";

const sxPropsRecord = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
    fontWeight: 500,
    fontSize: getToken("--cl-widget-font-size-note"),
    a: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
  },
  desc: {
    color: getToken("--cl-widget-color-static-fg-note"),
  },
  logo: {
    fontSize: "20px",
  },
} satisfies SxPropsRecord;

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={sxPropsRecord.root}>
      <Box sx={sxPropsRecord.desc}>{t("chat.footer.poweredBy")} </Box>
      <Link
        href="https://cresclab.com"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        Crescendo lab
        <ClIcon icon="FooterIcon" sx={sxPropsRecord.logo} />
      </Link>
    </Box>
  );
};

export { Footer };
