import { rawIconArrowDown } from "./raw/icon-arrow-down";
import { rawIconArrowLeft } from "./raw/icon-arrow-left";
import { rawIconArrowRight } from "./raw/icon-arrow-right";
import { rawIconAttachment } from "./raw/icon-attachment";
import { rawIconAvatar } from "./raw/icon-avatar";
import { rawIconFooterIcon } from "./raw/icon-footer-icon";
import { rawIconSendIcon } from "./raw/icon-send-icon";
import { rawIconWebChat } from "./raw/icon-web-chat";

function svgToObjUrl(svg: string) {
  return URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
}

const iconUrls = {
  ArrowDown: svgToObjUrl(rawIconArrowDown),
  ArrowLeft: svgToObjUrl(rawIconArrowLeft),
  ArrowRight: svgToObjUrl(rawIconArrowRight),
  Attachment: svgToObjUrl(rawIconAttachment),
  Avatar: svgToObjUrl(rawIconAvatar),
  FooterIcon: svgToObjUrl(rawIconFooterIcon),
  SendIcon: svgToObjUrl(rawIconSendIcon),
  WebChat: svgToObjUrl(rawIconWebChat),
} satisfies Record<string, string>;

type IconName = keyof typeof iconUrls;

export { iconUrls };
export type { IconName };
