import type { ComponentRef } from "react";
import type { ClassNameRecord } from "../types/ClassNameRecord";
import type { SxProps } from "../types/SxProps";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import {
  createTheme,
  GlobalStyles,
  iconClasses,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { z } from "zod";
import { rawCssVidstackBase } from "../raw/css-vidstack-base";
import { rawCssVidstackPlyr } from "../raw/css-vidstack-plyr";
import { rawCssViewerJs } from "../raw/css-viewer-js";
import { getFontSize, getToken, tokens } from "./tokens";

const lineClampPrefixPlugin: NonNullable<
  Parameters<typeof createCache>[0]["stylisPlugins"]
>[0] = (element) => {
  if (element.type !== "decl" || element.props !== "line-clamp") return;
  if (!Array.isArray(element.parent?.children)) return;
  element.return = [
    `-webkit-${element.value}`,
    "display: -webkit-box;",
    "-webkit-box-orient: vertical;",
    "overflow: hidden;",
    element.value,
  ].join("");
};

const classNameRecord = {
  root: "cl-widget",
  fixed: "cl-widget-fixed",
  alignRight: "cl-widget-align-right",
  alignTop: "cl-widget-align-top",
  alignBottom: "cl-widget-align-bottom",
} satisfies ClassNameRecord;

const theme = createTheme({
  cssVariables: {
    cssVarPrefix: "cl-widget",
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          /**
           * Because we use 600 for the primary color, we need to adjust the
           * palette accordingly.
           *
           * - CL 600 => MUI 500
           * - CL 500 => MUI 400
           * - CL 400 => MUI 300
           */
          "50": "#F3F9FF",
          "100": "#E7F2FF",
          "200": "#B5D7FF",
          "300": "#7DAFFF",
          "400": "#5998FF",
          "500": "#4586F0",
          "600": "#1F56E5",
        },
        error: {
          "100": "#FFF3EF",
          "200": "#FFEBE4",
          "300": "#FFBAAB",
          "400": "#FF8569",
          "500": "#E9573B",
          "600": "#D93721",
          "700": "#CF2A18",
        },
        grey: {
          "100": "#F8F9FB",
          "200": "#E7EBEF",
          "300": "#D7DEE4",
          "400": "#C7D0D9",
          "500": "#BBC6D1",
          "600": "#AFBCC9",
          "700": "#8C9CAB",
          "800": "#5C7388",
          "900": "#425A70",
        },
      },
    },
    dark: false,
  },
  typography: {
    fontFamily: "inherit",
    h1: {
      fontSize: getFontSize(24),
      fontWeight: 500,
    },
    h3: {
      fontSize: getFontSize(16),
      fontWeight: 700,
    },
    body1: {
      fontSize: getToken("--cl-widget-font-size-body"),
    },
  },
  shape: {
    borderRadius: 12,
  },
  zIndex: {
    mobileStepper: 0,
    speedDial: 0,
    appBar: 0,
    drawer: 0,
    modal: 0,
    snackbar: 0,
    tooltip: 0,
    fab: 0,
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          translate: "-50% 50%",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`.${iconClasses.root}`]: {
            fontSize: "1em",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          width: "28px",
          height: "28px",
          fontSize: "16px",
          minHeight: "auto",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: "28px",
          height: "28px",
          fontSize: "16px",
        },
      },
    },
  },
});

const globalStyles = ((theme) => ({
  [`.${classNameRecord.root}`]: {
    "&&": {
      display: "flex",
      flex: 1,
      alignSelf: "stretch",
      flexDirection: "column",
      backgroundColor: "transparent",
      fontSize: getToken("--cl-widget-font-size-body"),
      letterSpacing: "normal",
      fontFamily:
        '"Roboto", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Helvetica Neue", sans-serif',
      pointerEvents: "none",
      "&>*": {
        pointerEvents: "auto",
      },
      textarea: {
        fontFamily: "inherit",
      },

      /* Defensive reset */

      hyphens: "auto",
      lineHeight: "normal",
      overflowWrap: "anywhere",
      whiteSpace: "pre-wrap",
      wordBreak: "normal",
      /**
       * Force flex content to wrap to prevent other elements from distorting or
       * overflowing.
       */
      "*": {
        minWidth: 0,
      },
      /* Prevent image from distortion */
      img: {
        maxWidth: "100%",
        objectFit: "cover",
      },

      gap: 12,
      height: "100%",
    },

    [`&.${classNameRecord.fixed}`]: {
      position: "fixed",
      [`&.${classNameRecord.alignRight}`]: {
        right: 0,
      },
      [`&:not(.${classNameRecord.alignRight})`]: {
        left: 0,
      },
      [`&.${classNameRecord.alignBottom}`]: {
        bottom: 0,
      },
      [`&:not(.${classNameRecord.alignBottom})`]: {
        top: 0,
      },
      height: "100vh",
      minWidth: "300px",
      padding: 24,
    },
    [`&.${classNameRecord.alignRight}`]: {
      alignItems: "flex-end",
    },
    [`&.${classNameRecord.alignBottom}`]: {
      justifyContent: "flex-end",
    },
    [`&.${classNameRecord.alignTop}`]: {
      flexDirection: "column-reverse",
      justifyContent: "flex-end",
    },

    /**
     * VidStack
     */
    "[data-media-player]": {
      "&, *": {
        minWidth: "auto",
        "--plyr-color-main": theme.vars.palette.primary.main,
      },
    },
    ...tokens(theme),
  },
})) satisfies SxProps;

const thirdPartyCssNode: React.ReactNode = (() => {
  const thirdPartyCssRecord = {
    rawCssVidstackBase,
    rawCssVidstackPlyr,
    rawCssViewerJs,
  } satisfies Record<string, string>;
  return Object.entries(thirdPartyCssRecord).map(([key, css]) => (
    <style
      key={key}
      // eslint-disable-next-line react-dom/no-dangerously-set-innerhtml
      dangerouslySetInnerHTML={{ __html: css }}
    />
  ));
})();

const AlignVerticalSchema = z.enum(["top", "bottom"]);
const AlignHorizontalSchema = z.enum(["left", "right"]);

namespace ThemeProvider {
  export type AlignVertical = z.infer<typeof AlignVerticalSchema>;
  export type AlignHorizontal = z.infer<typeof AlignHorizontalSchema>;
  export interface Props {
    fixed?: boolean;
    alignVertical?: AlignVertical;
    alignHorizontal?: AlignHorizontal;
    children: React.ReactNode;
  }
}

const ThemeProviderInternal: React.FC<ThemeProvider.Props> = ({
  fixed,
  alignVertical = "top",
  alignHorizontal = "left",
  children,
}) => {
  const rootClassName = useMemo(
    () =>
      clsx(
        classNameRecord.root,
        fixed && classNameRecord.fixed,
        alignVertical === "bottom" && classNameRecord.alignBottom,
        alignHorizontal === "right" && classNameRecord.alignRight,
        alignVertical === "top" && classNameRecord.alignTop,
      ),
    [alignHorizontal, alignVertical, fixed],
  );
  const [cssCacheElement, setCssCacheElement] =
    useState<null | ComponentRef<"div">>(null);
  const emotionCache = useMemo(() => {
    if (!cssCacheElement) return null;
    return createCache({
      key: "cl-widget",
      container: cssCacheElement,
      stylisPlugins: [lineClampPrefixPlugin],
    });
  }, [cssCacheElement]);
  return (
    <MuiThemeProvider theme={theme}>
      {!emotionCache ? null : (
        <CacheProvider value={emotionCache}>
          <ScopedCssBaseline className={rootClassName}>
            {thirdPartyCssNode}
            <GlobalStyles styles={globalStyles} />
            {children}
          </ScopedCssBaseline>
        </CacheProvider>
      )}
      <div ref={setCssCacheElement} data-cl-widget-css-cache />
    </MuiThemeProvider>
  );
};

const ThemeProvider = Object.assign(ThemeProviderInternal, {
  AlignHorizontalSchema,
  AlignVerticalSchema,
  classNameRecord,
  theme,
});

export { ThemeProvider };
