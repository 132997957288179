import type { SxPropsRecord } from "../types/SxPropsRecord";
import { Button, type ButtonProps, type ButtonTypeMap } from "@mui/material";
import { useMemo } from "react";
import { defuSx } from "../theme/defuSx";

const sxPropsRecord = {
  root: {
    borderRadius: "4px",
  },
} satisfies SxPropsRecord;

namespace ClButton {
  export type Props<
    RootComponent extends React.ElementType = ButtonTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = ButtonProps<RootComponent, AdditionalProps>;
  export type Type = typeof Button;
}

const ClButton: ClButton.Type = <
  RootComponent extends React.ElementType = ButtonTypeMap["defaultComponent"],
  AdditionalProps = object,
>(
  props: ClButton.Props<RootComponent, AdditionalProps>,
) => {
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.root), [props.sx]);
  return <Button {...props} sx={sx} />;
};

export { ClButton };
