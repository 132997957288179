import type { DistributiveOmit, Overwrite } from "@mui/types";
import type React from "react";
import type { ClassNameRecord } from "../../../types/ClassNameRecord";

import type { SxPropsRecord } from "../../../types/SxPropsRecord";
import {
  Box,
  type BoxProps,
  Typography,
  type TypographyProps,
} from "@mui/material";
import clsx from "clsx";
import { motion } from "motion/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClIcon } from "../../../components/ClIcon";
import { ClIconButton } from "../../../components/ClIconButton";
import { useVerticalReverse } from "../../../context";
import { defuSx } from "../../../theme/defuSx";

const classNameRecord = {
  root: "cl-widget-chat-header",
  started: "cl-widget-chat-header-started",
} satisfies ClassNameRecord;

const sxPropsRecord = {
  root: (theme) => ({
    position: "relative",
    backgroundColor: theme.vars.palette.primary.main,
    color: theme.vars.palette.primary.contrastText,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    paddingBlockStart: "12px",
    paddingBlockEnd: "40px",
    paddingInline: "20px",
    minHeight: "52px",
    [`&.${classNameRecord.started}`]: {
      paddingBlockEnd: "12px",
    },
  }),
  row: {
    gap: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  right: {
    display: "flex",
    gap: "inherit",
    flexDirection: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
    marginLeft: "auto",
  },
  closeButtonIconReverse: {
    transform: "rotate(180deg)",
  },
} satisfies SxPropsRecord;

namespace Header {
  export interface Props
    extends Overwrite<
      DistributiveOmit<
        BoxProps<typeof motion.div>,
        "children" | "component" | "style"
      >,
      {
        started?: boolean;
        titleProps?: DistributiveOmit<
          TypographyProps<"h1" | "h3">,
          "children" | "component"
        >;
        backButtonProps?: DistributiveOmit<
          ClIconButton.Props,
          "children" | "component"
        >;
        closeButtonProps?: DistributiveOmit<
          ClIconButton.Props,
          "children" | "component"
        >;
      }
    > {}
}

const Header: React.FC<Header.Props> = ({
  started,
  titleProps,
  backButtonProps,
  closeButtonProps,
  ...props
}) => {
  const { t } = useTranslation();
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.root), [props.sx]);
  const className = useMemo(
    () => clsx(classNameRecord.root, started && classNameRecord.started),
    [started],
  );
  const verticalReverse = useVerticalReverse();
  return (
    <Box layout {...props} sx={sx} className={className} component={motion.div}>
      <Box sx={sxPropsRecord.row}>
        {!started ? null : (
          <>
            <ClIconButton color="inherit" {...backButtonProps}>
              <ClIcon icon="ArrowLeft" />
            </ClIconButton>
            <Typography variant="h3" {...titleProps}>
              {t("chat.header.title")}
            </Typography>
          </>
        )}
        <Box sx={sxPropsRecord.right}>
          <ClIconButton color="inherit" {...closeButtonProps}>
            <ClIcon
              icon="ArrowDown"
              {...(!verticalReverse
                ? null
                : {
                    sx: sxPropsRecord.closeButtonIconReverse,
                  })}
            />
          </ClIconButton>
        </Box>
      </Box>
      {started ? null : (
        <Typography variant="h1" {...titleProps}>
          {t("chat.startScreen.welcomeMessage")}
        </Typography>
      )}
    </Box>
  );
};

export { Header };
