import { makeApi } from "@zodios/core";
import { z } from "zod";

import { SignedUrlSchema } from "../../model";

const apiSignedUrl = makeApi([
  {
    alias: "generate",
    method: "post",
    path: "/api/v1/signed_url",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          fileName: z.string(),
          fileSize: z.number(),
        }),
      },
    ],
    response: SignedUrlSchema,
  },
]);

export { apiSignedUrl };
