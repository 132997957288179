import type { SxPropsRecord } from "../../../../../types/SxPropsRecord";

import { Box, Fab, TextareaAutosize } from "@mui/material";
import useEventCallback from "@mui/utils/useEventCallback";
import {
  type ComponentProps,
  type ComponentRef,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ClIcon } from "../../../../../components/ClIcon";
import { ClIconButton } from "../../../../../components/ClIconButton";
import { useClSdkDefaultUiOptions } from "../../../../../context";
import { getToken } from "../../../../../theme/tokens";
import { mergeRefs } from "../../../../../utils/mergeRefs";

const sxPropsRecord = {
  outer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: "0 12px 8px",
  },
  inner: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: "4px",
    backgroundColor: theme.vars.palette.common.white,
    gap: "4px",
  }),
  textarea: {
    minHeight: "40px",
    maxHeight: "98px",
    padding: "4px 8px",
    border: "none",
    borderRadius: "4px",
    appearance: "none",
    color: getToken("--cl-widget-color-static-fg-body"),
    fontSize: getToken("--cl-widget-font-size-body"),
    resize: "none",
    "&::placeholder": {
      color: getToken("--cl-widget-color-static-fg-placeholder"),
    },
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
    "&>*": {
      display: "flex",
      flexDirection: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
      gap: "inherit",
    },
  },
  sendButton: (theme) => ({
    boxShadow: "none",
    ":not(:disabled)": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }),
} satisfies SxPropsRecord;

namespace BottomPanel {
  export interface Props {
    textAreaProps?: React.ComponentProps<"textarea">;
    onSendingMessage?: () => void;
  }
}

const BottomPanel: React.FC<BottomPanel.Props> = ({
  onSendingMessage,
  textAreaProps,
}) => {
  const { t } = useTranslation();
  const clSdkDefaultUiOptionsContext = useClSdkDefaultUiOptions();
  const [inputValue, setInputValue] = useState("");
  const [onComposition, setOnComposition] = useState(false);
  const localRef = useRef<ComponentRef<"textarea">>(null);
  const trimmedInputValue = useMemo(() => inputValue.trim(), [inputValue]);
  const canSendMessage = trimmedInputValue.length > 0;
  const sendMessage: NonNullable<
    NonNullable<typeof clSdkDefaultUiOptionsContext>["onSendMessage"]
  > = useEventCallback((...args) => {
    onSendingMessage?.();
    return clSdkDefaultUiOptionsContext?.onSendMessage?.(...args);
  });
  const sendTextMessage = useEventCallback(() => {
    if (!canSendMessage) return;
    sendMessage({
      content: inputValue,
    });
    setInputValue("");
    localRef.current?.focus();
  });
  const sendFileMessage = useEventCallback(async (files: Array<File>) => {
    if (files.length === 0) return;
    const file = files[0];
    if (!file) return;
    sendMessage({
      content: file,
    });
  });
  const textareaRef = useEventCallback(((...args) =>
    mergeRefs(
      textAreaProps?.ref,
      localRef,
    )(...args)) satisfies ComponentProps<"textarea">["ref"]);
  return (
    <Box sx={sxPropsRecord.outer}>
      <Box sx={sxPropsRecord.inner}>
        <Box
          component={TextareaAutosize}
          sx={sxPropsRecord.textarea}
          minRows={1}
          maxRows={99}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={t("chat.textArea.placeholder")}
          onCompositionStart={() => {
            setOnComposition(true);
          }}
          onCompositionEnd={() => {
            setOnComposition(false);
          }}
          onKeyDown={(e) => {
            if (!canSendMessage) return;
            if (e.key !== "Enter") return;
            if (e.shiftKey || e.metaKey || e.ctrlKey || e.altKey) return;
            if (onComposition) return;
            e.preventDefault();
            sendTextMessage();
          }}
          {...textAreaProps}
          ref={textareaRef}
        />
        <Box sx={sxPropsRecord.actions}>
          <Box>
            <ClIconButton
              onClick={(e) => {
                e.currentTarget.querySelector("input")?.click();
              }}
            >
              <input
                type="file"
                style={{
                  display: "none",
                }}
                inert
                onChange={(e) => {
                  const element = e.currentTarget;
                  if (!element.files) return;
                  const files = Array.from(element.files);
                  sendFileMessage(files);
                  element.value = "";
                }}
              />
              <ClIcon icon="Attachment" />
            </ClIconButton>
          </Box>
          <Box>
            <Fab
              sx={sxPropsRecord.sendButton}
              disabled={inputValue.trim().length === 0}
              onClick={(e) => {
                if (!canSendMessage) return;
                e.preventDefault();
                sendTextMessage();
              }}
            >
              <ClIcon icon="SendIcon" />
            </Fab>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { BottomPanel };
