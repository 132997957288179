import type { ClSdk } from "../../ClSdk";
import type { ClApi } from "../ClApi";
import { createQueryStore } from "../ClApi/createQueryStore";
import { ClDebug } from "../ClDebug";

namespace ClChannel {
  export interface Options {
    clSdk: ClSdk;
  }
}

class ClChannel {
  clSdk: ClSdk;
  clDebug: ClDebug;
  $configQuery: createQueryStore.QueryStore<
    Awaited<ReturnType<ClApi["channelConfig"]["getConfig"]>>
  >;
  private destroyTasks: Array<() => void> = [];
  constructor(options: ClChannel.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({
      clSdk: this.clSdk,
      module: "ClChannel",
    });
    const abortController = new AbortController();
    this.$configQuery = createQueryStore({
      query: async () =>
        this.clSdk.clApi.channelConfig.getConfig({
          params: {
            clWebChannelId: this.clSdk.clWebChannelId,
          },
          signal: abortController.signal,
        }),
    });
    this.destroyTasks.push(() => {
      abortController.abort();
    });
    this.destroyTasks.push(
      this.$configQuery.subscribe(this.syncChannelConfigToFeatures),
    );
    this.syncChannelConfigToFeatures();
  }
  syncChannelConfigToFeatures = () => {
    const query = this.$configQuery.get();
    const connected =
      query.status === "success" && query.data.status === "connected";
    this.clSdk.atoms.$features.set({
      clChat: connected && query.data.webChatModuleEnabled,
      clRe: connected && query.data.webTrackingEnabled,
    });
  };
  destroy = () => {
    this.destroyTasks.forEach((task) => task());
  };
}

export { ClChannel };
