/**
 * Cloned from etude
 *
 * - <https://github.com/chatbotgang/etude/blob/e6a896b/packages/etude/timer/delay.ts>
 */

class AbortError extends Error {
  override name = "AbortError";
}

export function delay(ms: number, abortSignal?: AbortSignal): Promise<void> {
  const promise = new Promise<void>((resolve, reject) => {
    const timeout = setTimeout(() => {
      if (abortSignal) {
        abortSignal.removeEventListener("abort", abort);
      }
      resolve();
    }, ms);
    function abort() {
      clearTimeout(timeout);
      reject(new AbortError("abort"));
    }
    if (abortSignal) {
      abortSignal.addEventListener("abort", abort, { once: true });
    }
  });

  return promise;
}
