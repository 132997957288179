import type { ClSdkDefaultUi } from "./ClSdkDefaultUi";

import { createContext, useContext } from "react";

const ClSdkDefaultUiOptionsContext = createContext<ClSdkDefaultUi.Props | null>(
  null,
);

function useClSdkDefaultUiOptions() {
  const options = useContext(ClSdkDefaultUiOptionsContext);
  return options;
}

/**
 * Align bottom by default. Reverse if top.
 */
function useVerticalReverse() {
  const clSdkDefaultUiOptionsContext = useClSdkDefaultUiOptions();
  return clSdkDefaultUiOptionsContext?.alignVertical === "top";
}

export {
  ClSdkDefaultUiOptionsContext,
  useClSdkDefaultUiOptions,
  useVerticalReverse,
};
