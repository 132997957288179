import type { MessageSchema } from "@chatbotgang/web-sdk-core/model";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import type React from "react";
import type { z } from "zod";
import type { SxPropsRecord } from "../../../../types/SxPropsRecord";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { ClButton } from "../../../../components/ClButton";
import { ClIcon } from "../../../../components/ClIcon";
import { defuSx } from "../../../../theme/defuSx";
import { getToken } from "../../../../theme/tokens";
import { MessageDateTime } from "../Dialogue/Messages/Message/MessageDateTime";

const sxPropsRecord = {
  root: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    paddingBlock: "8px",
    paddingInline: "12px",
    flexDirection: "row",
    gap: "4x",
    alignItems: "center",
    backgroundColor: getToken("--cl-widget-color-static-bg-container"),
    color: getToken("--cl-widget-color-static-fg-body"),
    fontSize: getToken("--cl-widget-font-size-note"),
    textAlign: "left",
    "&&": {
      boxShadow: "none",
    },
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  content: {
    lineClamp: "2",
  },
  dateTime: {
    color: getToken("--cl-widget-color-static-fg-note"),
    fontSize: getToken("--cl-widget-font-size-note"),
  },
  icon: {
    "&&": {
      fontSize: "16px",
      marginLeft: "auto",
    },
  },
} satisfies SxPropsRecord;

namespace StartDialogue {
  export interface Props
    extends Overwrite<
      DistributiveOmit<ClButton.Props, "children" | "component">,
      {
        message: z.infer<typeof MessageSchema>;
      }
    > {}
}

const StartDialogue: React.FC<StartDialogue.Props> = ({
  message,
  ...props
}) => {
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.root), [props.sx]);
  return (
    <ClButton variant="contained" color="inherit" {...props} sx={sx}>
      <Box sx={sxPropsRecord.main}>
        <Box sx={sxPropsRecord.content}>
          {message.multimedia?.fileName || message.content}
        </Box>
        <Box sx={sxPropsRecord.dateTime}>
          <MessageDateTime date={message.createdAt} />
        </Box>
      </Box>
      <ClIcon icon="ArrowRight" sx={sxPropsRecord.icon} />
    </ClButton>
  );
};

export { StartDialogue };
