import type { ClSdk } from "@chatbotgang/web-sdk-core/ClSdk";
import type { initI18n } from "@chatbotgang/web-sdk-default-ui/i18n/utils";
import type { Dialogue } from "@chatbotgang/web-sdk-default-ui/model";

import { $dialogueId } from "@chatbotgang/web-sdk-core/store/dialogueIdStore";
import { ClSdkDefaultUi } from "@chatbotgang/web-sdk-default-ui";
import { useStore } from "@nanostores/react";
import { noop } from "es-toolkit";
import { computed, type ReadableAtom } from "nanostores";
import { type ComponentProps, useMemo } from "react";
import { chatStores } from "../stores";
import { ClWidgetPropsContext } from "./ClWidgetPropsContext";

namespace ClWidget {
  export interface Props<
    TI18nBackend = object,
    TLanguages extends Array<string> = initI18n.DefaultLanguages,
  > {
    clSdk: ClSdk;
    uiOptions?: Pick<
      ClSdkDefaultUi.Props<TI18nBackend, TLanguages>,
      | "i18n"
      | "fixed"
      | "alignHorizontal"
      | "alignVertical"
      | "autoFocusChatInput"
    >;
  }
  export interface Type {
    <
      TI18nBackend = object,
      TLanguages extends Array<string> = initI18n.DefaultLanguages,
    >(
      props: Props<TI18nBackend, TLanguages>,
    ): React.ReactNode;
  }
}

const onStartDialogue: ComponentProps<
  typeof ClSdkDefaultUi
>["onStartDialogue"] = () => {
  const dialogueId = $dialogueId.get();
  if (dialogueId) {
    chatStores.setSelectedDialogueId(dialogueId);
  }
};

const onSelectDialogue: ComponentProps<
  typeof ClSdkDefaultUi
>["onSelectDialogue"] = (dialogue) => {
  chatStores.setSelectedDialogueId(dialogue.id);
};
const onUnselectDialogue = () => {
  chatStores.clearSelectedDialogueId();
};

const Display: React.FC = () => {
  const clWidgetProps = ClWidgetPropsContext.useClWidgetProps();
  const opened = useStore(chatStores.$opened);
  const dialogue = useStore(clWidgetProps.clSdk.clChat.$dialogue);
  const selectedDialogueId = useStore(chatStores.$selectedDialogueId);
  const $dialogues = useMemo<ReadableAtom<Array<Dialogue>>>(() => {
    if (!dialogue) {
      const empty: Array<Dialogue> = [];
      return computed([], () => empty);
    }
    const $dialogues = computed([dialogue.$messages], (messages) => {
      const dialogues: Array<Dialogue> = [
        {
          id: dialogue.dialogueId,
          messages,
        },
      ];
      return dialogues;
    });
    return $dialogues;
  }, [dialogue]);
  const dialogues = useStore($dialogues);
  if (!dialogue) return null;
  return (
    <ClSdkDefaultUi
      {...clWidgetProps.uiOptions}
      chatOpened={opened}
      onChatOpen={chatStores.open}
      onChatClose={chatStores.close}
      dialogues={dialogues}
      {...(!selectedDialogueId
        ? null
        : {
            selectedDialogueId,
          })}
      onStartDialogue={onStartDialogue}
      onSelectDialogue={onSelectDialogue}
      onUnselectDialogue={onUnselectDialogue}
      onSendMessage={dialogue?.sendMessage ?? noop}
      onMessageRead={({ messageItem }) =>
        dialogue?.readMessages({ message: messageItem.message })
      }
    />
  );
};

/**
 * Check enabled features.
 */
const ClWidgetInternal = () => {
  const clWidgetProps = ClWidgetPropsContext.useClWidgetProps();
  const enabledChat = useStore(clWidgetProps.clSdk.features.$clChat);
  const enabledSome = enabledChat;
  if (!enabledSome) return null;
  return <Display />;
};

const ClWidget: ClWidget.Type = (props) => {
  return (
    <ClWidgetPropsContext
      value={props as ClWidget.Props<object, Array<string>>}
    >
      <ClWidgetInternal />
    </ClWidgetPropsContext>
  );
};

export { ClWidget };
