import type { z } from "zod";
import { persistentAtom } from "@nanostores/persistent";

import { DialogueIdSchema } from "../model";
import { generateKey } from "../utils/generateKey";

const DIALOGUE_ID_KEY = generateKey("DIALOGUE_ID");

export const $dialogueId = persistentAtom<z.infer<
  typeof DialogueIdSchema
> | null>(DIALOGUE_ID_KEY, null, {
  encode: (v) => v || "",
  decode: (v) => (!v ? null : DialogueIdSchema.parse(v)),
});
