async function isImageFile(file: File): Promise<boolean> {
  const img = document.createElement("img");
  img.src = URL.createObjectURL(file);
  function cleanup() {
    URL.revokeObjectURL(img.src);
    img.remove();
  }
  return new Promise((resolve) => {
    img.onload = () => {
      cleanup();
      resolve(true);
    };
    img.onerror = () => {
      cleanup();
      resolve(false);
    };
  });
}

export { isImageFile };
