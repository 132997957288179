import type { AxiosResponse } from "axios";
import type { ClSdk } from "../../ClSdk";

import { atom, computed, type ReadableAtom } from "nanostores";
import PQueue from "p-queue";
import { ClDebug } from "../ClDebug";
import { client } from "./client";
import { eventEmitter } from "./eventEmitter";
import { getStoredUTM, UTM_STORAGE_KEY } from "./utm";

const location = window.location;
const document = window.document;

const scriptEl = document.currentScript;
const dataDomain = scriptEl?.getAttribute("data-domain");

namespace ClRe {
  export interface Options {
    clSdk: ClSdk;
  }
  export interface Item {
    item_id: string;
    item_name: string;
    link?: string;
    image_link?: string;
    price?: number;
    description?: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    quantity?: number;
  }
  export interface RecordType {
    <
      TEventName extends string =
        | "page_view"
        | "view_items"
        | "add_to_cart"
        | "remove_from_cart"
        | "purchase",
    >(
      /**
       * Send a page_view event whenever a customer visits a page on your website. This helps track user navigation and page popularity.
       */
      eventName: TEventName,
      data: TEventName extends "page_view"
        ? {
            props: {
              page_path: string;
              page_title: string;
            };
          }
        : TEventName extends "view_items" | "add_to_cart" | "remove_from_cart"
          ? {
              props: {
                currency?: string;
                items: Array<Item>;
              };
            }
          : TEventName extends "purchase"
            ? {
                props: {
                  transaction_id: string;
                  revenue: number;
                  tax?: number;
                  shipping?: number;
                  currency?: string;
                  coupon?: string;
                  items: Array<Item>;
                };
              }
            : {
                props: Record<PropertyKey, unknown>;
              },
    ): Promise<undefined | AxiosResponse<unknown>>;
  }
}

let firstPageLoadRecordSent = false;

class ClRe {
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  private $status: ReadableAtom<"loading" | "error" | "disabled" | "enabled">;
  readonly $pageViewEnabled: ReadableAtom<boolean>;
  private $eventPool = atom<Array<Parameters<ClRe.RecordType>>>([]);
  private destroyTasks: Array<() => void> = [];
  constructor(options: ClRe.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({ clSdk: this.clSdk, module: "ClRe" });
    this.$status = computed(
      [
        this.clSdk.clDeviceId.$deviceIdQuery,
        this.clSdk.clChannel.$configQuery,
        this.clSdk.features.$clRe,
        this.clSdk.features.$clDeviceId,
        this.clSdk.clAuth.$authenticateQuery,
      ],
      (
        deviceIdQuery,
        configQuery,
        reEnabled,
        clDeviceIdOptions,
        authenticateQuery,
      ): ReturnType<(typeof this.$status)["get"]> => {
        const deviceIdEnabled = clDeviceIdOptions !== false;
        if (
          (deviceIdEnabled && deviceIdQuery.status === "error") ||
          configQuery.status === "error" ||
          authenticateQuery.status === "error"
        ) {
          this.clDebug.error("ClRe error", {
            deviceIdQuery: deviceIdQuery.error,
            configQuery: configQuery.error,
            authenticateQuery: authenticateQuery.error,
          });
          return "error";
        }
        if (
          (deviceIdEnabled && deviceIdQuery.status === "loading") ||
          configQuery.status === "loading" ||
          authenticateQuery.status === "loading"
        ) {
          this.clDebug.debug("ClRe loading");
          return "loading";
        }
        const result =
          reEnabled && authenticateQuery.data.dialogueId
            ? "enabled"
            : "disabled";
        this.clDebug.debug("ClRe result", {
          result,
          configQuery: configQuery.data,
          authenticateQuery: authenticateQuery.data,
        });
        return result;
      },
    );
    this.$pageViewEnabled = computed(this.clSdk.atoms.$plugins, (plugins) => {
      let disabled = false;
      plugins.forEach((plugin) => {
        if (
          typeof plugin["~modulesOptions"]?.clRe?.disablePageView === "boolean"
        ) {
          disabled = plugin["~modulesOptions"].clRe.disablePageView;
        }
      });
      return !disabled;
    });
  }
  init() {
    eventEmitter.on("pageView", this.pageViewHandler);
    this.destroyTasks.push(() => {
      eventEmitter.off("pageView", this.pageViewHandler);
    });
    this.destroyTasks.push(
      this.$status.subscribe((status, previousStatus) => {
        if (previousStatus === "loading" && status === "enabled") {
          const eventPoolItems = this.$eventPool.get();
          this.clDebug.debug("Resolving event pool", eventPoolItems);
          eventPoolItems.forEach(([eventName, options]) => {
            this.record(eventName, options);
          });
          this.$eventPool.set([]);
          return;
        }
        // clear pool if disabled
        if (status === "disabled") {
          this.$eventPool.set([]);
        }
      }),
    );
    if (!firstPageLoadRecordSent) {
      this.pageViewHandler();
      firstPageLoadRecordSent = true;
    }
  }

  private recordInternal: ClRe.RecordType = async (eventName, options) => {
    const clSdkData = Object.fromEntries(
      Object.entries(localStorage).flatMap(([key]) =>
        key === UTM_STORAGE_KEY
          ? []
          : key.startsWith("CL_SDK_")
            ? [[key, localStorage.getItem(key)]]
            : [],
      ),
    );

    const props: typeof options.props =
      options?.props && typeof options.props === "object"
        ? { ...options.props }
        : {};

    const authenticateQuery = this.clSdk.clAuth.$authenticateQuery.get();

    // Build payload
    const payload = {
      id: this.clSdk.clWebChannelId,
      l: navigator.language,
      n: eventName,
      u: location.href,
      d: dataDomain || null,
      r: document.referrer || null,
      p: {
        ...getStoredUTM(),
        ...props,
        ...clSdkData,
        ...(authenticateQuery.status !== "success"
          ? null
          : {
              /**
               * [[WCCS - FE] bring encrypted_dialogue_id to CDH](https://app.asana.com/0/0/1209067727128157/1209496380223857/f)
               */
              ...(!authenticateQuery.data.edId
                ? null
                : { ed_id: authenticateQuery.data.edId }),
              ...(!authenticateQuery.data.nonce
                ? null
                : { nonce: authenticateQuery.data.nonce }),
            }),
      },
      ...(!("revenue" in props) ? null : { $: props?.revenue }),
    };
    this.clDebug.debug("Record", payload);
    return client.post(this.clSdk.env.$RE_DATA_API.get(), payload);
  };
  pQueue = new PQueue({ concurrency: 1 });
  private recordInternalQueued: typeof this.recordInternal = (...args) =>
    this.pQueue.add(() => this.recordInternal(...args), {
      throwOnTimeout: true,
    });
  record = (...args: Parameters<typeof this.recordInternal>) => {
    const status = this.$status.get();
    if (status === "loading") {
      this.$eventPool.set([...this.$eventPool.get(), args]);
      return;
    }
    if (status === "disabled" || status === "error") {
      return;
    }
    return this.recordInternalQueued(...args);
  };
  pageViewHandler = () => {
    if (!this.$pageViewEnabled.get()) return;
    this.clDebug.debug("Page view", { location, title: document.title });
    this.record("page_view", {
      props: { page_path: location.pathname, page_title: document.title },
    });
  };
  destroy = () => {
    this.destroyTasks.forEach((task) => task());
    this.clDebug.debug("ClRe destroyed");
  };
}

export { ClRe };
