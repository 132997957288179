import type { BoxTypeMap } from "@mui/system";
import type { OverridableComponent, Overwrite } from "@mui/types";
import type { ClassNameRecord } from "../../../types/ClassNameRecord";

import type { SxPropsRecord } from "../../../types/SxPropsRecord";
import type { TargetAndTransitionRecord } from "../../../types/TargetAndTransitionRecord";
import { Box, type BoxProps, type Theme as MaterialTheme } from "@mui/material";
import clsx from "clsx";
import { AnimatePresence, motion } from "motion/react";
import { useMemo } from "react";
import { useVerticalReverse } from "../../../context";
import { defuSx } from "../../../theme/defuSx";
import { getToken } from "../../../theme/tokens";
import { defineCssVarUtils } from "../../../utils/defineCssVarUtils";

const classNameRecord = {
  started: "cl-widget-chat-started",
} satisfies ClassNameRecord;

const cssVarUtils = defineCssVarUtils({
  "--cl-widget-chat-room-width": "300px",
  "--cl-widget-chat-room-max-height": "500px",
});

const sxPropsRecord = {
  root: (theme) => ({
    ...cssVarUtils.props,
    display: "flex",
    overflow: "hidden",
    width: cssVarUtils.getValue("--cl-widget-chat-room-width"),
    height: "max-content",
    maxHeight: cssVarUtils.getValue("--cl-widget-chat-room-max-height"),
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: theme.vars.shape.borderRadius,
    backgroundColor: getToken("--cl-widget-color-static-bg-container"),
    boxShadow: getToken("--cl-widget-box-shadow-drop-shadow"),
    [`&:not(.${classNameRecord.started})`]: {
      flex: "none",
    },
  }),
} satisfies SxPropsRecord;

namespace ChatCard {
  export interface OwnProps {
    opened?: boolean;
    started?: boolean;
  }
  export type Props<
    RootComponent extends React.ElementType = BoxTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = Overwrite<BoxProps<RootComponent, AdditionalProps>, OwnProps>;
  export type TypeMap<
    AdditionalProps = object,
    RootComponent extends React.ElementType = "div",
    Theme extends object = MaterialTheme,
  > = BoxTypeMap<Overwrite<AdditionalProps, OwnProps>, RootComponent, Theme>;
  export type Type = OverridableComponent<TypeMap>;
}

const ChatCard: ChatCard.Type = <
  RootComponent extends React.ElementType = BoxTypeMap["defaultComponent"],
  AdditionalProps = object,
>({
  opened,
  started,
  ...props
}: ChatCard.Props<RootComponent, AdditionalProps>) => {
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.root), [props.sx]);
  const className = useMemo(
    () => clsx(props.className, started && classNameRecord.started),
    [props.className, started],
  );
  const verticalReverse = useVerticalReverse();
  const targetAndTransitionRecord = useMemo(
    () =>
      ({
        closed: {
          opacity: 0,
          translate: verticalReverse ? "0 -200px" : "0 200px",
        },
        opened: { opacity: 1, translate: "0 0" },
      }) satisfies TargetAndTransitionRecord,
    [verticalReverse],
  );
  return (
    <AnimatePresence>
      {!opened ? null : (
        <Box
          component={motion.div}
          {...props}
          sx={sx}
          className={className}
          initial={targetAndTransitionRecord.closed}
          animate={targetAndTransitionRecord.opened}
          exit={targetAndTransitionRecord.closed}
        />
      )}
    </AnimatePresence>
  );
};

export { ChatCard };
