import type { ClSdkDefaultUi } from "./ClSdkDefaultUi";

/**
 * Override default options for the demo.
 */
const demoOptions = {
  fixed: false,
  autoFocusChatInput: false,
} satisfies Partial<ClSdkDefaultUi.Props>;

const defaultOptions = {
  fixed: true,
  alignVertical: "bottom",
  alignHorizontal: "right",
  autoFocusChatInput: true,
} satisfies Partial<ClSdkDefaultUi.Props>;

export { defaultOptions, demoOptions };
