import type { z } from "zod";
import type { ClSdk } from "../../ClSdk";
import type { DialogueIdSchema, Message, MessageSchema } from "../../model";
import type { ClWs } from "../ClWs";

import { secondsToMilliseconds } from "date-fns";
import { flow, uniqBy } from "es-toolkit";
import { atom, computed } from "nanostores";
import { delay } from "../../utils/delay";
import { isAudioFile } from "../../utils/isAudioFile";
import { isImageFile } from "../../utils/isImageFile";
import { isVideoFile } from "../../utils/isVideoFile";
import { effect } from "../../utils/nanostores/effect";
import { ClDebug } from "../ClDebug";

namespace ClDialogue {
  export interface Options {
    clSdk: ClSdk;
    dialogueId: z.infer<typeof DialogueIdSchema>;
  }
  export interface SendMessageOptions {
    content: string | File;
    abortController?: AbortController;
  }
}

const normalizeMessages = flow(
  (messages: Array<z.infer<typeof MessageSchema>>) =>
    uniqBy(messages, (item) => item.id),
  (messages) =>
    [...messages].sort((a, b) => {
      if (a.createdAt === null) {
        return -1;
      }
      if (b.createdAt === null) {
        return 1;
      }
      return a.createdAt.getTime() - b.createdAt.getTime();
    }),
);

class ClDialogue {
  static MaxWsMessagesSize = 100;
  static INTERVAL = secondsToMilliseconds(5);
  clSdk: ClSdk;
  debug: ClDebug;
  dialogueId: z.infer<typeof DialogueIdSchema>;
  atoms = {
    $messagesFromApi: atom<Array<z.infer<typeof MessageSchema>>>([]),
    $messagesFromWs: atom<Array<z.infer<typeof MessageSchema>>>([]),
  };
  $messages = computed(
    [this.atoms.$messagesFromApi, this.atoms.$messagesFromWs],
    (api, ws) => normalizeMessages([...api, ...ws]),
  );
  private destroyTasks: Array<() => void> = [];

  constructor(options: ClDialogue.Options) {
    this.clSdk = options.clSdk;
    this.debug = new ClDebug({ clSdk: this.clSdk, module: "Dialogue" });
    this.dialogueId = options.dialogueId;
    this.debug.debug("Initialized", {
      clSdk: this.clSdk,
      dialogueId: this.dialogueId,
    });
    this.destroyTasks.push(
      effect(this.clSdk.clAuth.$authenticateQuery, (authenticateQuery) => {
        let abortController: AbortController | null = null;
        function clearAbortController() {
          if (abortController) {
            abortController.abort();
            abortController = null;
          }
        }
        function resetAbortController() {
          clearAbortController();
          abortController = new AbortController();
          return abortController;
        }
        (async () => {
          if (authenticateQuery.status !== "success") {
            clearAbortController();
            return;
          }
          const abortController = resetAbortController();
          const signal = abortController.signal;
          while (true) {
            const messages = await this.clSdk.clApi.message.getList({
              signal,
            });
            this.updateMessagesFromApi(normalizeMessages(messages));
            await delay(ClDialogue.INTERVAL, signal);
          }
        })();
        return function cleanup() {
          clearAbortController();
        };
      }),
    );

    this.destroyTasks.push(
      (() => {
        const handler: (e: ClWs.EventMap["message"]) => void = (e) => {
          this.pushMessageWs(e.payload);
        };
        this.clSdk.clWs.mitt.on("message", handler);
        return () => {
          this.clSdk.clWs.mitt.off("message", handler);
        };
      })(),
    );
  }

  sendMessage = async (options: ClDialogue.SendMessageOptions) => {
    if (typeof options.content === "string") {
      return this.clSdk.clApi.message.send(
        {
          content: options.content,
          dialogueId: this.dialogueId,
          type: "plain_text",
        },
        {
          ...(!options.abortController?.signal
            ? null
            : {
                signal: options.abortController.signal,
              }),
        },
      );
    }
    const uploadTask = this.clSdk.clUpload.upload({
      file: options.content,
      ...(!options.abortController
        ? null
        : {
            abortController: options.abortController,
          }),
    });
    const uploadResult = await uploadTask.execution.promise;
    return this.clSdk.clApi.message.send(
      {
        dialogueId: this.dialogueId,
        type: (await isImageFile(options.content))
          ? "image"
          : (await isAudioFile(options.content))
            ? /**
               * Audio files are not supported yet.
               */
              "file"
            : (await isVideoFile(options.content))
              ? "video"
              : "file",
        multimediaId: uploadResult.multimediaUuid,
      },
      {
        ...(!options.abortController?.signal
          ? null
          : {
              signal: options.abortController.signal,
            }),
      },
    );
  };

  pushMessageWs = (
    message:
      | z.infer<typeof MessageSchema>
      | Array<z.infer<typeof MessageSchema>>,
  ) => {
    const newMessages: Array<z.infer<typeof MessageSchema>> = Array.isArray(
      message,
    )
      ? message
      : [message];

    const messages = this.atoms.$messagesFromWs.get();
    this.atoms.$messagesFromWs.set(
      normalizeMessages([...messages, ...newMessages]).slice(
        -ClDialogue.MaxWsMessagesSize,
      ),
    );
  };

  updateMessagesFromApi = (messages: Array<z.infer<typeof MessageSchema>>) => {
    this.atoms.$messagesFromApi.set(messages);
  };

  /**
   * For now, messages cannot be marked as read partially due to the lack of
   * the API endpoint.
   */
  readMessages = async (_options: { message: Message }) => {
    await this.clSdk.clApi.message.read(undefined);
  };

  destroy() {
    this.destroyTasks.forEach((task) => task());
  }
}

export { ClDialogue };
