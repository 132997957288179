import type { z } from "zod";
import { persistentAtom } from "@nanostores/persistent";
import { nanoid } from "nanoid";

import { SiteBoundUserUuidSchema } from "../model";
import { generateKey } from "../utils/generateKey";

const SITE_BOUND_USER_UUID_KEY = generateKey("SITE_BOUND_USER_UUID");

export const $siteBoundUserUuid = persistentAtom<
  z.infer<typeof SiteBoundUserUuidSchema>
>(SITE_BOUND_USER_UUID_KEY, SiteBoundUserUuidSchema.parse(nanoid()));
