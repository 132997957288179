import type { z } from "zod";
import type { ClSdk } from "../../ClSdk";
import { parseJson } from "@crescendolab/parse-json";
import { secondsToMilliseconds } from "date-fns";
import { isPrimitive } from "es-toolkit";
import mitt from "mitt";
import { computed, type ReadableAtom } from "nanostores";
import urlJoin from "url-join";

import { camelcaseKeys } from "../../utils/camelcaseKeys";
import { ClDebug } from "../ClDebug";
import { EventSchema } from "./schema";
import { Ws } from "./Ws";

namespace ClWs {
  export interface Options {
    clSdk: ClSdk;
  }
  // eslint-disable-next-line ts/consistent-type-definitions -- Prefer `type` over `interface` for improved autocompletion and type checking.
  export type EventMap = {
    message: z.infer<typeof EventSchema>;
  };
}

class ClWs {
  clSdk: ClSdk;
  clDebug: ClDebug;
  $ws: ReadableAtom<Ws | null>;
  mitt = mitt<ClWs.EventMap>();
  constructor(options: ClWs.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({ clSdk: this.clSdk, module: "ClWs" });
    this.$ws = computed(
      [this.clSdk.env.$WS_HOST, this.clSdk.clAuth.$token],
      (wsHost, token) => {
        if (!wsHost || !token) return null;
        return new Ws({
          clSdk: this.clSdk,
          url: urlJoin(wsHost, "/api/v1/clients", "wss"),
          repeatLimit: Infinity,
          pingMsg: "ping",
          protocols: [token],
          pingTimeout: secondsToMilliseconds(5),
        });
      },
    );

    this.$ws.subscribe((ws, oldWs) => {
      if (oldWs) {
        this.clDebug.debug("Destroying old WS", { oldWs });
        this.destroyWs(oldWs);
      }
      if (ws) {
        this.clDebug.debug("Setting up new WS", { ws });
        this.setupWs(ws);
      }
    });
  }
  setupWs = (ws: Readonly<Ws>) => {
    ws.mitt.on("message", this.messageHandler);
  };
  messageHandler = (e: MessageEvent) => {
    const json = parseJson(e.data, {
      fallback: undefined,
    });
    if (json === undefined) return;
    if (isPrimitive(json)) return;
    const data = camelcaseKeys(json);
    const parsedResult = EventSchema.safeParse(data);
    if (!parsedResult.success) return;
    this.mitt.emit("message", parsedResult.data);
  };
  destroyWs = (ws: Readonly<Ws>) => {
    ws.close();
    ws.mitt.off("message", this.messageHandler);
  };
  destroy = () => {
    const ws = this.$ws.get();
    if (ws) {
      this.destroyWs(ws);
    }
  };
}

export { ClWs };
