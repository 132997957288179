import type { StringOrLiteral } from "@chatbotgang/web-sdk-core/types/StringOrLiteral";

import type { InitOptions, ResourceLanguage } from "i18next";
import type { F } from "ts-toolbelt";
import type { PartialDeep } from "type-fest";
import defu from "defu";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { z } from "zod";

const DefaultLanguageSchema = z.enum(["en", "zh-TW", "ja", "th"]);
const defaultSupportedLanguages = DefaultLanguageSchema.options;
type DefaultLanguage = (typeof defaultSupportedLanguages)[number];

type StrictTranslationKey =
  | "chat.startScreen.welcomeMessage"
  | "chat.startScreen.title"
  | "chat.startScreen.description"
  | "chat.startScreen.startButton"
  | "chat.header.title"
  | "chat.textArea.placeholder"
  | "chat.messages.unreadDividerLabel"
  | "chat.messageBadge.newMessage"
  | "chat.footer.poweredBy";

type StrictTranslation = Partial<Record<StrictTranslationKey, string>>;

interface StrictResourceLang {
  translation: StrictTranslation;
}

type StrictResource = Record<DefaultLanguage, StrictResourceLang>;

const defaultResources = {
  en: {
    translation: {
      "chat.startScreen.welcomeMessage": "Welcome",
      "chat.startScreen.title": "Chat with us",
      "chat.startScreen.description":
        "Ask us anything, we’ll reply as soon as we can.",
      "chat.startScreen.startButton": "Start a conversation",
      "chat.header.title": "Chat with us",
      "chat.messages.unreadDividerLabel": "New message below",
      "chat.textArea.placeholder": "Enter your message",
      "chat.messageBadge.newMessage": "New ↓",
      "chat.footer.poweredBy": "Powered by",
    } satisfies Record<StrictTranslationKey, string>,
  },
  "zh-TW": {
    translation: {
      "chat.startScreen.welcomeMessage": "歡迎",
      "chat.startScreen.title": "線上對話",
      "chat.startScreen.description": "歡迎隨時詢問，我們會盡快回覆",
      "chat.startScreen.startButton": "開始對話",
      "chat.header.title": "線上對話",
      "chat.messages.unreadDividerLabel": "以下為未讀訊息",
      "chat.textArea.placeholder": "輸入訊息",
      "chat.messageBadge.newMessage": "新訊息 ↓",
      "chat.footer.poweredBy": "Powered by",
    } satisfies Record<StrictTranslationKey, string>,
  },
  ja: {
    translation: {
      "chat.startScreen.welcomeMessage": "ようこそ！",
      "chat.startScreen.title": "オンラインチャット",
      "chat.startScreen.description":
        "ご不明な点がございましたら、ご遠慮なくお問い合わせください。",
      "chat.startScreen.startButton": "チャットをはじめる",
      "chat.header.title": "オンラインチャット",
      "chat.messages.unreadDividerLabel": "ここから未読メッセージ",
      "chat.messageBadge.newMessage": "新着",
      "chat.textArea.placeholder": "メッセージを入力してください。",
      "chat.footer.poweredBy": "運営会社",
    } satisfies Record<StrictTranslationKey, string>,
  },
  th: {
    translation: {
      "chat.startScreen.welcomeMessage": "ยินดีต้อนรับ",
      "chat.startScreen.title": "พูดคุยกับเรา",
      "chat.startScreen.description":
        "หากมีคำถามหรือข้อสงสัย สามารถสอบถามเราได้ทุกเมื่อ เรายินดีตอบกลับอย่างรวดเร็วครับ!",
      "chat.startScreen.startButton": "เริ่มการสนทนา",
      "chat.header.title": "พูดคุยกับเรา",
      "chat.messages.unreadDividerLabel": "ข้อความใหม่ด้านล่าง",
      "chat.messageBadge.newMessage": "ข้อความใหม่ ↓",
      "chat.textArea.placeholder": "พิมพ์ข้อความของคุณ",
      "chat.footer.poweredBy": "Powered by",
    } satisfies Record<StrictTranslationKey, string>,
  },
} as const satisfies StrictResource;

const defaultModules: Array<initI18n.Module> = [LanguageDetector];

const defaultInitOptions: InitOptions = {
  resources: defaultResources,
  fallbackLng: "en",
  react: {
    useSuspense: false,
  },
};

namespace initI18n {
  export type Module = Parameters<typeof i18next.use>[0];
  export type DefaultLanguages = Array<StringOrLiteral<DefaultLanguage>>;
  export interface TypedResourceLanguage extends ResourceLanguage {
    translation: Record<StrictTranslationKey, string>;
  }
  export interface InternalInitOptions<
    TI18nBackend = object,
    TLanguages extends Array<string> = DefaultLanguages,
  > extends PartialDeep<InitOptions<TI18nBackend>> {
    resources?: NoInfer<{
      [key in
        | TLanguages[number]
        | DefaultLanguage]?: PartialDeep<TypedResourceLanguage>;
    }>;
    supportedLngs?: F.Narrow<TLanguages>;
    fallbackLng?: NoInfer<TLanguages[number]>;
    lng?: NoInfer<TLanguages[number]>;
  }
  export interface Options<
    TI18nBackend = object,
    TLanguages extends Array<string> = DefaultLanguages,
  > {
    initialOptions?: initI18n.InternalInitOptions<TI18nBackend, TLanguages>;

    modules?:
      | Array<Module>
      | ((defaultModules: Array<Module>) => Array<Module>);
  }
}

function defineI18nOptions<
  TI18nBackend = object,
  TLanguages extends Array<string> = initI18n.DefaultLanguages,
>(options: initI18n.Options<TI18nBackend, TLanguages>) {
  return options;
}

const initI18n = <
  TI18nBackend = object,
  TLanguages extends Array<string> = initI18n.DefaultLanguages,
>(
  i18n: typeof i18next,
  options?: initI18n.Options<TI18nBackend, TLanguages>,
) => {
  const modules: Array<initI18n.Module> =
    typeof options?.modules === "function"
      ? options.modules(defaultModules)
      : Array.isArray(options?.modules)
        ? [...defaultModules, ...options.modules]
        : defaultModules;
  modules.forEach((module) => i18n.use(module));
  const mergedInitialOptions = defu<
    initI18n.Options<TI18nBackend, TLanguages>,
    [InitOptions]
  >(options?.initialOptions, defaultInitOptions) satisfies InitOptions;
  i18n.init(mergedInitialOptions);
  return i18n;
};

initI18n(i18next);

export {
  defaultInitOptions,
  defaultSupportedLanguages as defaultLang,
  defaultResources,
  defineI18nOptions,
  initI18n,
};
export type {
  DefaultLanguage,
  StrictResource,
  StrictResourceLang,
  StrictTranslationKey,
};
