import type { SxPropsRecord } from "../../../../../types/SxPropsRecord";
import { Divider, type DividerProps, type DividerTypeMap } from "@mui/material";
import { useMemo } from "react";
import { defuSx } from "../../../../../theme/defuSx";
import { getToken } from "../../../../../theme/tokens";

const sxPropsRecord = {
  root: (theme) => ({
    color: theme.vars.palette.primary.main,
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    "&::before, &::after": {
      borderTopColor: theme.vars.palette.primary.main,
      flex: 1,
      minWidth: "2em",
    },
    "&&>*": {
      fontSize: getToken("--cl-widget-font-size-note"),
      textAlign: "center",
      textWrap: "pretty",
      whiteSpace: "pre-wrap",
    },
  }),
} satisfies SxPropsRecord;

namespace ChatDivider {
  export type Props<
    RootComponent extends
      React.ElementType = DividerTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = DividerProps<RootComponent, AdditionalProps>;
  export type Type = typeof Divider;
}

const ChatDivider: ChatDivider.Type = <
  RootComponent extends React.ElementType = DividerTypeMap["defaultComponent"],
  AdditionalProps = object,
>(
  props: ChatDivider.Props<RootComponent, AdditionalProps>,
) => {
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.root), [props.sx]);
  return <Divider {...props} sx={sx} />;
};

export { ChatDivider };
