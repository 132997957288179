import type { Promisable, Writable } from "type-fest";

import type { ClSdk } from "../../ClSdk";
import { env } from "../../env";
import { type DeviceId, DeviceIdSchema } from "../../model";
import { onFromBridgeSchema } from "../../schema/bridge/on";
import { generateKey } from "../../utils/generateKey";
import { createQueryStore } from "../ClApi/createQueryStore";
import { ClDebug } from "../ClDebug";

const DEVICE_ID_KEY = generateKey("DEVICE_ID");

class BridgeIframeLoadError extends Error {}
class BridgeDeviceIdTimeoutError extends Error {}

namespace ClDeviceId {
  export interface FeatureOptions {
    storage?: {
      getValue: (args: { signal: AbortSignal }) => Promisable<string | null>;
      setValue: (args: {
        deviceId: string;
        signal: AbortSignal;
      }) => Promisable<void>;
    };
    bridgeOptions?: GetDeviceIdFromBridgeOptions;
  }
  export interface Options {
    clSdk: ClSdk;
  }
  export interface GetDeviceIdFromBridgeOptions {
    timeoutMs?: number;
  }
}

class ClDeviceId {
  static BridgeIframeLoadError = BridgeIframeLoadError;
  static BridgeDeviceIdTimeoutError = BridgeIframeLoadError;
  static defaultFeatureOptions = {
    storage: {
      getValue: () => localStorage.getItem(DEVICE_ID_KEY) || null,
      setValue: ({ deviceId }) => {
        localStorage.setItem(DEVICE_ID_KEY, deviceId);
      },
    },
    bridgeOptions: {
      timeoutMs: 5000,
    },
  } satisfies ClDeviceId.FeatureOptions;
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  cache: null | {
    deviceId: DeviceId | null;
    timestamp: Date;
    source: "bridge" | "storage";
  } = null;
  $deviceIdQuery: createQueryStore.QueryStore<DeviceId | null>;
  constructor(options: ClDeviceId.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({ clSdk: this.clSdk, module: "ClDeviceId" });
    const deps = [
      this.clSdk.env.$BRIDGE_URL,
      this.clSdk.features.$clDeviceId,
    ] as const;
    const writableDeps: Writable<typeof deps> = [...deps];
    type QueryStoreValue = typeof createQueryStore<
      DeviceId | null,
      Writable<typeof writableDeps>
    >;
    const enabled: Parameters<QueryStoreValue>[0]["enabled"] = ({
      deps: [_bridgeUrl, featureOptions],
    }) => {
      return featureOptions !== false;
    };
    const query: Parameters<QueryStoreValue>[0]["query"] = async (options) => {
      if (!enabled(options)) {
        this.clDebug.error("Query is not enabled");
        throw new Error("Query is not enabled");
      }
      const {
        deps: [bridgeUrl, featureOptions],
        signal,
      } = options;
      const setupFeatureOptions =
        typeof featureOptions !== "object" ? null : featureOptions;
      const mergedFeatureOptions: ClDeviceId.FeatureOptions = {
        ...ClDeviceId.defaultFeatureOptions,
        ...setupFeatureOptions,
        bridgeOptions: {
          ...ClDeviceId.defaultFeatureOptions.bridgeOptions,
          ...setupFeatureOptions?.bridgeOptions,
        },
      };
      this.clDebug.debug("Getting device ID");
      const deviceId = await this.getDeviceId({
        ...mergedFeatureOptions,
        bridgeUrl,
        signal,
      });
      this.clDebug.debug("Got device ID", deviceId);
      return deviceId;
    };
    this.$deviceIdQuery = createQueryStore({
      deps: writableDeps,
      enabled,
      query,
    });
    this.clDebug.debug("Initialized");
  }

  /**
   * Get the device ID from the following sources:
   *
   * 1. bridge
   * 2. storage
   */
  private getDeviceId = async (
    options: ClDeviceId.FeatureOptions & {
      bridgeUrl?: string;
      signal: AbortSignal;
    },
  ): Promise<DeviceId | null> => {
    this.clDebug.debug("Getting device ID", "cache:", this.cache);
    if (this.cache) {
      return this.cache.deviceId;
    }
    const storage: NonNullable<ClDeviceId.FeatureOptions["storage"]> =
      options.storage || ClDeviceId.defaultFeatureOptions.storage;
    const deviceId = await (async () => {
      this.clDebug.debug("Getting device ID from bridge");
      try {
        const deviceId = await this.getDeviceIdFromBridge(options);
        this.clDebug.debug("Got device ID from bridge", deviceId);
        options.signal.throwIfAborted();
        await storage.setValue({ deviceId, signal: options.signal });
        options.signal.throwIfAborted();
        this.cache = { deviceId, timestamp: new Date(), source: "bridge" };
        return deviceId;
      } catch (err) {
        this.clDebug.error("Failed to get device ID from bridge", err);
        const fallbackValue = await storage.getValue({
          signal: options.signal,
        });
        this.clDebug.debug("Got device ID from storage", fallbackValue);
        options.signal.throwIfAborted();
        const deviceId =
          typeof fallbackValue === "string"
            ? DeviceIdSchema.parse(fallbackValue)
            : fallbackValue;
        this.cache = { deviceId, timestamp: new Date(), source: "storage" };
        return deviceId;
      }
    })();
    return deviceId;
  };

  private getDeviceIdFromBridge = async (
    options: Parameters<typeof ClDeviceId.prototype.getDeviceId>[0],
  ): Promise<DeviceId> => {
    this.clDebug.debug("Getting device ID from bridge", options);
    return new Promise<Awaited<ReturnType<typeof this.getDeviceIdFromBridge>>>(
      (resolve, reject) => {
        const iframe = document.createElement("iframe");
        iframe.src = options.bridgeUrl ?? env.BRIDGE_URL;
        iframe.style.display = "none";
        iframe.sandbox.add("allow-scripts");
        iframe.sandbox.add("allow-same-origin");
        Object.assign(iframe, {
          "data-cl-vivace-bridge": "",
        });
        const iframeErrorListener: Parameters<
          typeof iframe.addEventListener
        >[1] = (event) => {
          cleanup();
          const err = new BridgeIframeLoadError("Failed to load iframe");
          Object.assign(err, { cause: event });
          reject(err);
        };
        iframe.addEventListener("error", iframeErrorListener);
        const listener: Parameters<
          typeof window.addEventListener<"message">
        >[1] = (event) => {
          if (iframe.contentWindow !== event.source) {
            return;
          }
          const data: unknown = event.data;
          const parsedData = onFromBridgeSchema.safeParse(data);
          if (!parsedData.success) return;
          if (parsedData.data.type !== DEVICE_ID_KEY) return;
          cleanup();
          resolve(parsedData.data.data);
        };
        window.addEventListener("message", listener);
        const timeout = setTimeout(() => {
          this.clDebug.error("getDeviceIdFromBridge timeout");
          cleanup();
          reject(new BridgeDeviceIdTimeoutError("Timeout"));
        }, options.bridgeOptions?.timeoutMs ?? ClDeviceId.defaultFeatureOptions.bridgeOptions.timeoutMs);
        const handleAbort: Parameters<
          typeof options.signal.addEventListener<"abort">
        >[1] = (e) => {
          cleanup();
          reject(e);
        };
        options.signal.addEventListener("abort", handleAbort);
        function cleanup() {
          window.removeEventListener("message", listener);
          iframe.removeEventListener("error", iframeErrorListener);
          options.signal.removeEventListener("abort", handleAbort);
          iframe.remove();
          clearTimeout(timeout);
        }
        document.body.appendChild(iframe);
      },
    );
  };
}

export { ClDeviceId };
