import type { BoxProps, Theme as MaterialTheme } from "@mui/material";
import type { BoxTypeMap } from "@mui/system";
import type { OverridableComponent } from "@mui/types";
import type { ClassNameRecord } from "../../../../../../types/ClassNameRecord";
import type { SxPropsRecord } from "../../../../../../types/SxPropsRecord";
import { Box } from "@mui/material";
import clsx from "clsx";
import React, { useMemo } from "react";
import { defuSx } from "../../../../../../theme/defuSx";
import { getToken } from "../../../../../../theme/tokens";
import { messageStyleUtils } from "./messageStyleUtils";

const sxPropsRecord = {
  root: {
    minWidth: "20px",
    maxWidth: `calc(100% - ${messageStyleUtils.cssVarUtils.getValue("--cl-widget-sender-margin")})`,
    padding: "8px",
    borderRadius: "8px",
    backgroundColor: messageStyleUtils.cssVarUtils.getValue(
      "--cl-widget-bubble-bg-color",
    ),
    color: messageStyleUtils.cssVarUtils.getValue(
      "--cl-widget-bubble-fg-color",
    ),
    boxShadow: getToken("--cl-widget-box-shadow-floating"),
  },
} satisfies SxPropsRecord;

const classNameRecord = {
  root: `cl-widget-chat-bubble`,
} satisfies ClassNameRecord;

namespace Bubble {
  export interface TypeMap<
    AdditionalProps = object,
    RootComponent extends React.ElementType = "div",
    Theme extends object = MaterialTheme,
  > extends BoxTypeMap<AdditionalProps, RootComponent, Theme> {}
  export type Props<
    RootComponent extends React.ElementType = TypeMap["defaultComponent"],
    AdditionalProps = object,
  > = BoxProps<RootComponent, AdditionalProps>;
  export type Type = OverridableComponent<
    BoxTypeMap<object, "div", MaterialTheme>
  >;
}

const BubbleInternal: Bubble.Type = <
  RootComponent extends React.ElementType = BoxTypeMap["defaultComponent"],
  AdditionalProps = object,
>(
  props: Bubble.Props<RootComponent, AdditionalProps>,
) => {
  const className = useMemo(
    () => clsx(classNameRecord.root, props.className),
    [props.className],
  );
  const sx = useMemo(() => defuSx(props.sx, sxPropsRecord.root), [props.sx]);
  return <Box {...props} className={className} sx={sx} />;
};

const Bubble = Object.assign(BubbleInternal, {
  sxPropsRecord,
  classNameRecord,
});

export { Bubble };
