import type { Message } from "@chatbotgang/web-sdk-core/model";

import type { initI18n } from "./i18n/utils";
import { pick } from "es-toolkit";
import { noop } from "es-toolkit/compat";
import { useMemo } from "react";
import {
  ClSdkDefaultUiOptionsContext,
  useClSdkDefaultUiOptions,
} from "./context";
import { defaultOptions } from "./defaultOptions";
import { I18nProvider } from "./i18n/I18nProvider";
import { ClChatFab } from "./modules/Chat/Fab";
import { ClChatMain } from "./modules/Chat/Main";
import { ThemeProvider } from "./theme/ThemeProvider";

namespace ClSdkDefaultUi {
  export interface Props<
    TI18nBackend = object,
    TLanguages extends Array<string> = initI18n.DefaultLanguages,
  > extends Pick<
      ThemeProvider.Props,
      "fixed" | "alignVertical" | "alignHorizontal"
    > {
    // Chat
    chatOpened?: ClChatMain.Props["opened"];
    onChatOpen?: ClChatFab.Props["onClick"];
    onChatClose?: ClChatMain.Props["onClose"];
    selectedDialogueId?: ClChatMain.Props["selectedDialogueId"];
    onStartDialogue?: ClChatMain.Props["onStartDialogue"];
    onSelectDialogue?: ClChatMain.Props["onSelectDialogue"];
    onUnselectDialogue?: ClChatMain.Props["onUnselectDialogue"];
    onSendMessage?: ClChatMain.Props["onSendMessage"];
    dialogues?: ClChatMain.Props["dialogues"];
    onMessageRead?: ClChatMain.Props["onMessageRead"];
    autoFocusChatInput?: boolean;

    i18n?: I18nProvider.Options<TI18nBackend, TLanguages>;
  }
  export type ScrollingTask = "bottom" | "unread";
}

function ClSdkDefaultUiInternal() {
  const clSdkDefaultUiOptionsContext = useClSdkDefaultUiOptions();
  const allUnreadMessages = useMemo<Array<Message>>(
    () =>
      clSdkDefaultUiOptionsContext?.dialogues?.flatMap<Message>(
        (d) => d.messages,
      ) ?? [],
    [clSdkDefaultUiOptionsContext?.dialogues],
  );
  const unreadCount = useMemo(
    () =>
      allUnreadMessages.filter((m) => !m.readAt && m.role === "receiver")
        .length,
    [allUnreadMessages],
  );
  return (
    <>
      <ClChatMain
        opened={clSdkDefaultUiOptionsContext?.chatOpened ?? false}
        onClose={clSdkDefaultUiOptionsContext?.onChatClose ?? noop}
        dialogues={clSdkDefaultUiOptionsContext?.dialogues ?? []}
        {...(!clSdkDefaultUiOptionsContext?.selectedDialogueId
          ? null
          : {
              selectedDialogueId:
                clSdkDefaultUiOptionsContext.selectedDialogueId,
            })}
        onStartDialogue={clSdkDefaultUiOptionsContext?.onStartDialogue ?? noop}
        onSelectDialogue={
          clSdkDefaultUiOptionsContext?.onSelectDialogue ?? noop
        }
        onUnselectDialogue={
          clSdkDefaultUiOptionsContext?.onUnselectDialogue ?? noop
        }
        onMessageRead={clSdkDefaultUiOptionsContext?.onMessageRead}
      />
      <ClChatFab
        color="primary"
        onClick={
          clSdkDefaultUiOptionsContext?.chatOpened
            ? clSdkDefaultUiOptionsContext?.onChatClose
            : clSdkDefaultUiOptionsContext?.onChatOpen
        }
        count={unreadCount}
      />
    </>
  );
}

/**
 * Crescendo Lab Web SDK Default UI.
 */
const ClSdkDefaultUi: React.FC<ClSdkDefaultUi.Props> = (props) => {
  const options = useMemo<ClSdkDefaultUi.Props>(
    () => ({
      ...defaultOptions,
      ...props,
    }),
    [props],
  );
  const themeProviderProps = useMemo<Omit<ThemeProvider.Props, "children">>(
    () => pick(options, ["fixed", "alignVertical", "alignHorizontal"]),
    [options],
  );
  return (
    <ThemeProvider {...themeProviderProps}>
      <ClSdkDefaultUiOptionsContext value={options}>
        <I18nProvider>
          <ClSdkDefaultUiInternal />
        </I18nProvider>
      </ClSdkDefaultUiOptionsContext>
    </ThemeProvider>
  );
};

export { ClSdkDefaultUi };
