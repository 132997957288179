import { defineCssVarUtils } from "../utils/defineCssVarUtils";

const paletteTokens = defineCssVarUtils({
  "--cl-widget-base-font-size": "16px",
  "--color-widget-color-blue-10": "#F3F9FF",
  "--color-widget-color-blue-20": "#E7F2FF",
  "--color-widget-color-blue-30": "#B5D7FF",
  "--color-widget-color-blue-40": "#7DAFFF",
  "--color-widget-color-blue-50": "#5998FF",
  "--color-widget-color-blue-60": "#4586F0",
  "--color-widget-color-blue-70": "#1F56E5",
});

function getFontSize(pt: number) {
  return `calc(${paletteTokens.getValue("--cl-widget-base-font-size")} * ${pt} / 16)`;
}

const { getValue: getToken, props: tokens } = defineCssVarUtils((theme) => {
  return {
    ...paletteTokens.props,
    "--cl-widget-font-size-body": getFontSize(14),
    "--cl-widget-font-size-note": getFontSize(12),
    "--cl-widget-box-shadow-drop-shadow": [
      "0 9px 28px 0 #0000000d",
      "0 6px 16px 0 #00000014",
      "0 3px 6px 0 #0000001f",
    ].join(", "),
    "--cl-widget-box-shadow-floating": "0 0 8px 0 #223b5333",
    "--cl-widget-color-static-bg-container": theme.vars.palette.grey["100"],
    "--cl-widget-color-static-bg-page": theme.vars.palette.common.white,
    "--cl-widget-color-static-fg-body": theme.vars.palette.grey["900"],
    "--cl-widget-color-static-fg-note": theme.vars.palette.grey["700"],
    "--cl-widget-color-static-fg-placeholder": theme.vars.palette.grey["600"],
    "--cl-widget-color-static-fg-superior": theme.vars.palette.common.white,
  };
});

export { getFontSize, getToken, tokens };
