namespace sleep {
  export interface Options {
    ms: number;
    signal?: AbortSignal;
  }
}

const sleep = (options: sleep.Options): Promise<void> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(resolve, options.ms);
    if (options.signal) {
      const signal = options.signal;
      signal.addEventListener("abort", () => {
        clearTimeout(timeout);
        try {
          signal.throwIfAborted();
        } catch (e) {
          reject(e);
        }
      });
    }
  });
};

export { sleep };
