import { parseJson } from "@crescendolab/parse-json";
import { hoursToMilliseconds } from "date-fns";
import { omit } from "es-toolkit";
import { get } from "es-toolkit/compat";

import { generateKey } from "../../utils/generateKey";

// UTM parameters to track
const UTM_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
] as const;
type UtmKey = (typeof UTM_KEYS)[number];
type UtmParams = Partial<Record<UtmKey, string>>;
const UTM_STORAGE_KEY = generateKey("RE_UTM_PARAMS");
const TTL = 5 * hoursToMilliseconds(24); // 5 days in milliseconds

/**
 * Handle UTM parameters: parse, store with TTL, and retrieve.
 */
(function handleUTM() {
  const now = Date.now();
  const urlParams = new URLSearchParams(location.search);
  const utmPresent = UTM_KEYS.some((key) => urlParams.has(key));

  if (!utmPresent) return;

  // Extract UTM parameters from URL
  const utmParams: UtmParams = {};
  UTM_KEYS.forEach((key) => {
    const urlParam = urlParams.get(key);
    if (urlParam === null) return;
    utmParams[key] = urlParam;
  });
  const value = (() => {
    try {
      const str = JSON.stringify({ data: utmParams, timestamp: now });
      return str;
    } catch (e) {
      console.warn("UTM handling failed:", e);
      return null;
    }
  })();
  if (value === null) return;
  localStorage.setItem(UTM_STORAGE_KEY, value);
})();

/**
 * Retrieve valid UTM parameters from localStorage.
 */
function getStoredUTM(): UtmParams {
  const stored = localStorage.getItem(UTM_STORAGE_KEY);
  if (!stored) return {};

  const json = parseJson(stored, {
    fallback: undefined,
  });
  const timestamp = Number(get(json, "timestamp", undefined));
  const data: UtmParams = {
    ...// Inherit
    ((json && typeof json === "object"
      ? omit(
          json,
          // @ts-expect-error -- We don't need to check the keys for the json object
          UTM_KEYS,
        )
      : null) as Record<string, unknown>),
    ...(Object.fromEntries(
      UTM_KEYS.flatMap((key) => {
        const data = get(json, ["data", key], undefined);
        if (data === undefined || data === null) return [];
        return [[key, String(data)]] as const;
      }),
    ) satisfies UtmParams),
  };
  if (
    Number.isNaN(timestamp) ||
    // UTM invalid or expired
    Date.now() - timestamp > TTL ||
    !data
  ) {
    console.warn("Retrieving UTM failed");
    localStorage.removeItem(UTM_STORAGE_KEY);
    return {};
  }
  return data;
}

export { getStoredUTM, UTM_STORAGE_KEY };
