import type { SxProps } from "../types/SxProps";
import defu from "defu";

function defuSx(
  options: SxProps | undefined,
  ...sxProps: Array<SxProps>
): SxProps {
  // @ts-expect-error -- Ignore type error.
  return (theme) =>
    defu<NonNullable<SxProps>, Array<SxProps>>(
      (typeof options === "function" ? options(theme) : options) ?? {},
      ...sxProps.map((sxProp) =>
        typeof sxProp === "function" ? sxProp(theme) : sxProp,
      ),
    );
}

export { defuSx };
