import type { OverridableComponent, Overwrite } from "@mui/types";
import type { IconName } from "../icons";
import type { ClassNameRecord } from "../types/ClassNameRecord";
import type { SxPropsRecord } from "../types/SxPropsRecord";
import { Icon, type IconProps, type IconTypeMap } from "@mui/material";
import clsx from "clsx";
import { useMemo } from "react";
import { iconUrls } from "../icons";
import { defuSx } from "../theme/defuSx";
import { defineCssVarUtils } from "../utils/defineCssVarUtils";

const cssVarUtils = defineCssVarUtils({
  "--cl-widget-icon": "",
});

const classNameRecord = {
  mask: "cl-widget-icon-mask",
} satisfies ClassNameRecord;

const sxPropsRecord = {
  root: {
    display: "flex",
    width: "1em",
    minWidth: "1em",
    height: "1em",
    color: "inherit",
    [`&:not(.${classNameRecord.mask})`]: {
      background: `${cssVarUtils.getValue("--cl-widget-icon")} no-repeat`,
      backgroundSize: "100% 100%",
      backgroundColor: "transparent",
    },
    [`&.${classNameRecord.mask}`]: {
      backgroundColor: "currentcolor",
      mask: `${cssVarUtils.getValue("--cl-widget-icon")} no-repeat`,
      maskSize: "100% 100%",
    },
  },
} satisfies SxPropsRecord;

namespace ClIcon {
  export interface OwnProps {
    icon: IconName;
    mask?: boolean;
  }
  export type Props<
    RootComponent extends React.ElementType = IconTypeMap["defaultComponent"],
    AdditionalProps = object,
  > = Overwrite<IconProps<RootComponent, AdditionalProps>, OwnProps>;
  export interface TypeMap<
    AdditionalProps = object,
    RootComponent extends React.ElementType = "span",
  > extends IconTypeMap<Overwrite<AdditionalProps, OwnProps>, RootComponent> {}
  export type Type = OverridableComponent<TypeMap> & { muiName: string };
}

const ClIcon = <
  RootComponent extends React.ElementType = ClIcon.TypeMap["defaultComponent"],
  AdditionalProps = object,
>({
  icon,
  mask = true,
  ...props
}: ClIcon.Props<RootComponent, AdditionalProps>) => {
  const mergedSx = useMemo(
    () =>
      defuSx(
        props.sx,
        {
          [cssVarUtils.getKey("--cl-widget-icon")]: `url(${iconUrls[icon]})`,
        },
        sxPropsRecord.root,
      ),
    [icon, props.sx],
  );
  const className = useMemo(
    () => clsx(props.className, mask && classNameRecord.mask),
    [mask, props.className],
  );
  return (
    <Icon {...props} className={className} sx={mergedSx} data-cl-icon={icon} />
  );
};

export { ClIcon };
