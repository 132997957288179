import { z } from "zod";

import { MessageSchema } from "../../model";

const MessageEventSchema = z.object({
  eventId: z.string(),
  eventType: z.literal("message"),
  payload: MessageSchema,
});

const EventSchema = z.discriminatedUnion("eventType", [MessageEventSchema]);

export { EventSchema };
