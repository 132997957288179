async function isAudioFile(file: File): Promise<boolean> {
  const audio = document.createElement("audio");
  function cleanup() {
    URL.revokeObjectURL(audio.src);
    audio.remove();
  }
  audio.src = URL.createObjectURL(file);
  return new Promise((resolve) => {
    audio.onloadedmetadata = () => {
      cleanup();
      resolve(file.type.startsWith("audio/"));
    };
    audio.onerror = () => {
      cleanup();
      resolve(false);
    };
  });
}

export { isAudioFile };
