/**
 * An alternative to [`Promise.withResolvers`](https://github.com/tc39/proposal-promise-with-resolvers).
 */

function promiseWithResolvers<T = unknown>() {
  type Executor = ConstructorParameters<typeof Promise<T>>[0];
  let resolve: Parameters<Executor>[0];
  let reject: Parameters<Executor>[1];
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  // @ts-expect-error -- Promise executor being called synchronously.
  return { promise, resolve, reject };
}

export { promiseWithResolvers };
