import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { SxPropsRecord } from "../../../types/SxPropsRecord";

import { badgeClasses } from "@mui/material";
import { useMemo } from "react";
import { ClBadge } from "../../../components/ClBadge";
import { ClFab } from "../../../components/ClFab";
import { ClIcon } from "../../../components/ClIcon";
import { defuSx } from "../../../theme/defuSx";
import { defineCssVarUtils } from "../../../utils/defineCssVarUtils";

const cssVarUtils = defineCssVarUtils({
  "--cl-widget-chat-fab-size": "60px",
});

const sxPropsRecord = {
  badge: {
    [`.${badgeClasses.badge}`]: {
      pointerEvents: "none",
    },
  },
  fab: {
    ...cssVarUtils.props,
    width: cssVarUtils.getValue("--cl-widget-chat-fab-size"),
    height: cssVarUtils.getValue("--cl-widget-chat-fab-size"),
    fontSize: cssVarUtils.getValue("--cl-widget-chat-fab-size"),
    "&&": {
      backgroundColor: "transparent",
    },
  },
} satisfies SxPropsRecord;

namespace ClChatFab {
  export interface Props
    extends Overwrite<
      DistributiveOmit<ClFab.Props, "children">,
      {
        count?: number;
        badgeProps?: DistributiveOmit<
          ClBadge.Props,
          "children" | "badgeContent"
        >;
      }
    > {}
}

const ClChatFabInternal: React.FC<ClChatFab.Props> = ({
  badgeProps,
  count,
  ...props
}) => {
  const mergedBadgeSx = useMemo(
    () => defuSx(badgeProps?.sx, sxPropsRecord.badge),
    [badgeProps?.sx],
  );
  const mergedSx = useMemo(
    () => defuSx(props.sx, sxPropsRecord.fab),
    [props.sx],
  );
  return (
    <ClBadge
      color="error"
      max={9}
      {...badgeProps}
      sx={mergedBadgeSx}
      badgeContent={!count ? null : count}
    >
      <ClFab {...props} sx={mergedSx}>
        <ClIcon icon="WebChat" mask={false} />
      </ClFab>
    </ClBadge>
  );
};

const ClChatFab = Object.assign(ClChatFabInternal, {
  sxPropsRecord,
});

export { ClChatFab };
