import type { BoxProps } from "@mui/system";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { MessageItem } from "../../../../../model";
import type { ClassNameRecord } from "../../../../../types/ClassNameRecord";
import type { SxPropsRecord } from "../../../../../types/SxPropsRecord";

import { Box } from "@mui/material";
import useEventCallback from "@mui/utils/useEventCallback";
import clsx from "clsx";
import { secondsToMilliseconds } from "date-fns";
import {
  type ComponentProps,
  Fragment,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { defuSx } from "../../../../../theme/defuSx";
import { defineCssVarUtils } from "../../../../../utils/defineCssVarUtils";
import { ChatDivider } from "./Divider";
import { Message } from "./Message";

const classNameRecord = {
  root: "cl-widget-chat-messages-container",
  content: "cl-widget-chat-messages-content",
} satisfies ClassNameRecord;

const cssVarUtils = defineCssVarUtils({
  "--cl-widget-chat-messages-padding-block": "8px",
  "--cl-widget-chat-messages-padding-inline": "12px",
});

const sxPropsRecord = {
  root: {
    ...cssVarUtils.props,
    position: "relative",
    flex: 1,
    overflowY: "auto",
    marginBlockEnd: cssVarUtils.getValue(
      "--cl-widget-chat-messages-padding-block",
    ),
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    paddingBlock: cssVarUtils.getValue(
      "--cl-widget-chat-messages-padding-block",
    ),
    paddingBlockEnd: 0,
    paddingInline: cssVarUtils.getValue(
      "--cl-widget-chat-messages-padding-inline",
    ),
    gap: "12px",
  },
  bottomInView: {
    display: "block",
  },
} satisfies SxPropsRecord;

namespace Messages {
  export interface Props
    extends Overwrite<
      DistributiveOmit<BoxProps, "children" | "component">,
      {
        messageItems: Array<MessageItem>;
        contentProps?: DistributiveOmit<BoxProps, "children" | "component">;
        unreadDividerProps?: ChatDivider.Props;
        onBottomInView?: (inView: ReturnType<typeof useInView>) => void;
        onMessageRead?: Message.Props["onMessageRead"];
      }
    > {}
}

const Messages: React.FC<Messages.Props> = ({
  messageItems,
  contentProps,
  unreadDividerProps,
  onBottomInView,
  onMessageRead,
  ...props
}) => {
  const { t } = useTranslation();
  /**
   * Find the index of the first unread message.
   */
  const unreadDividerIndex = useMemo(() => {
    /// Return -1 if there are no messages, or if all messages are either read or unread.
    if (
      messageItems.length === 0 ||
      messageItems.every((item) => item.read) ||
      messageItems.every((item) => !item.read)
    ) {
      return -1;
    }
    return messageItems.findIndex((item) => !item.read);
  }, [messageItems]);

  const bottomInView = useInView();

  const onBottomInViewFn = useEventCallback<NonNullable<typeof onBottomInView>>(
    (...args) => {
      onBottomInView?.(...args);
    },
  );

  useEffect(() => {
    onBottomInViewFn(bottomInView);
  }, [bottomInView, onBottomInViewFn]);

  const messageReadRef = useRef<{
    message: Parameters<NonNullable<typeof onMessageRead>>[0];
    clear: () => void;
  }>(null);
  const handleMessageRead = useEventCallback(((message) => {
    const current = messageReadRef.current;
    if (current) {
      current.clear();
    }
    const timeout = setTimeout(() => {
      // eslint-disable-next-line ts/no-use-before-define -- In setTimeout.
      clear();
      onMessageRead?.(message);
    }, secondsToMilliseconds(0.5));
    const clear = () => {
      clearTimeout(timeout);
      messageReadRef.current = null;
    };
    messageReadRef.current = {
      /* Read the latest message. */
      message: !current
        ? message
        : (function ({ message: currentMessage }: typeof current) {
            const currentIndex = messageItems.findIndex(
              (item) =>
                item.message.id === currentMessage.messageItem.message.id,
            );
            const newIndex = messageItems.findIndex(
              (item) => item.message.id === message.messageItem.message.id,
            );
            return currentIndex > newIndex ? current.message : message;
          })(current),
      clear,
    };
  }) satisfies ComponentProps<typeof Message>["onMessageRead"]);
  /**
   * Clear messageReadRef on unmount.
   */
  useEffect(() => {
    return () => {
      if (!messageReadRef.current) return;
      messageReadRef.current.clear();
    };
  }, []);

  return (
    <Box
      {...props}
      className={clsx(classNameRecord.root, props.className)}
      sx={defuSx(props.sx, sxPropsRecord.root)}
    >
      <Box
        {...contentProps}
        className={clsx(classNameRecord.content, contentProps?.className)}
        sx={defuSx(contentProps?.sx, sxPropsRecord.content)}
      >
        {messageItems.length > 0
          ? messageItems.map((item, index) => {
              return (
                <Fragment key={item.message.id}>
                  {unreadDividerIndex === -1 ? null : index ===
                    unreadDividerIndex ? (
                    <ChatDivider {...unreadDividerProps}>
                      {unreadDividerProps?.children ??
                        t("chat.messages.unreadDividerLabel")}
                    </ChatDivider>
                  ) : null}
                  <Message
                    key={item.message.id}
                    messageItem={item}
                    onMessageRead={handleMessageRead}
                  />
                </Fragment>
              );
            })
          : null}
        <Box sx={sxPropsRecord.bottomInView} ref={bottomInView.ref} />
      </Box>
    </Box>
  );
};

export { Messages };
