import camelcaseKeysInternal, {
  type CamelCaseKeys,
  type EmptyTuple,
  type Options,
  type WithDefault,
} from "camelcase-keys";

function camelcaseKeys<T, OptionsType extends Options = Options>(
  input: T,
  options?: OptionsType,
): T extends Record<string, any> | ReadonlyArray<any>
  ? CamelCaseKeys<
      T,
      WithDefault<OptionsType["deep"], true>,
      WithDefault<OptionsType["pascalCase"], false>,
      WithDefault<OptionsType["exclude"], EmptyTuple>,
      WithDefault<OptionsType["stopPaths"], EmptyTuple>
    >
  : T {
  // @ts-expect-error -- Wrong type definition in the original package. Tested.
  return camelcaseKeysInternal(input, {
    deep: true,
    ...options,
  });
}

export { camelcaseKeys };
