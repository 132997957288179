/**
 * The schema for the listen method to receive a message from bridge.
 */

import { z } from "zod";

import { DeviceIdSchema } from "../../model";
import { deviceIdEventTypeSchema } from "./model";

const schemaArray = [
  z.object({
    type: deviceIdEventTypeSchema,
    data: DeviceIdSchema,
  }),
] satisfies [
  z.ZodDiscriminatedUnionOption<"type">,
  ...Array<z.ZodDiscriminatedUnionOption<"type">>,
];

const onFromBridgeSchema = z.discriminatedUnion("type", schemaArray);

export { onFromBridgeSchema };
