import type { ClSdk } from "../../ClSdk";
import type { SignedUrl } from "../../model";
import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";
import mitt from "mitt";
import PQueue from "p-queue";
import { promiseWithResolvers } from "../../utils/promiseWithResolvers";
import { ClDebug } from "../ClDebug";

/**
 * Allowing 2 concurrent uploads.
 *
 * Prevents too many requests from being sent at once otherwise other requests
 * will be blocked.
 */
const pQueue = new PQueue({ concurrency: 2 });

const client = axios.create();

const queuedPut: typeof client.put = async <
  T = unknown,
  R = AxiosResponse<T>,
  D = unknown,
>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
) => {
  const result = await pQueue.add(
    () => client.put<T, R, D>(url, data, config),
    {
      throwOnTimeout: true,
    },
  );
  return result;
};

class ClUploadExecutionError extends Error {
  constructor(message: string, options?: { cause?: unknown }) {
    super(message);
    this.name = "ClUploadExecutionError";
    Object.assign(this, options);
  }
}

namespace ClUploadExecution {
  export interface Options {
    clSdk: ClSdk;
  }
  // eslint-disable-next-line ts/consistent-type-definitions -- Type han
  export type UploadEvents = {
    /**
     * - Range: 0 ~ 1
     * - 0 stands for 0%
     * - 1 stands for 100%
     */
    progress: number;
    success: SignedUrl;
    error: ClUploadExecutionError;
  };
  export interface UploadOptions {
    file: File;
    signal?: AbortSignal;
  }
}

class ClUploadExecution {
  static GENERATED_PROGRESS = 0.1;
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  constructor(options: ClUploadExecution.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({
      clSdk: this.clSdk,
      module: "ClUploadExecution",
    });
  }

  upload(options: ClUploadExecution.UploadOptions) {
    const eventEmitter = mitt<ClUploadExecution.UploadEvents>();
    const resolvers = promiseWithResolvers<SignedUrl>();
    const resolve: typeof resolvers.resolve = (value) => {
      cleanup();
      resolvers.resolve(value);
    };
    const reject = ((reason: ClUploadExecutionError) => {
      if (options.signal?.aborted) {
        this.clDebug.info("Upload aborted", { reason });
      } else {
        this.clDebug.error("Failed to upload file", { reason });
      }
      cleanup();
      eventEmitter.emit("error", reason);
      resolvers.reject(reason);
    }) satisfies typeof resolvers.reject;
    this.clSdk.clApi.signedUrl
      .generate({
        fileName: options.file.name,
        fileSize: options.file.size,
      })
      .then(
        (response) => {
          eventEmitter.emit("progress", ClUploadExecution.GENERATED_PROGRESS);
          const uploadRequest = queuedPut(response.signedUrl, options.file, {
            onUploadProgress: (progressEvent) => {
              eventEmitter.emit(
                "progress",
                (progressEvent.loaded / options.file.size) *
                  (1 - ClUploadExecution.GENERATED_PROGRESS) +
                  ClUploadExecution.GENERATED_PROGRESS,
              );
            },
          });
          uploadRequest.then(
            () => {
              resolve(response);
            },
            (error) => {
              const clUploadError = new ClUploadExecutionError(
                "Failed to upload file",
                {
                  cause: error,
                },
              );
              reject(clUploadError);
            },
          );
        },
        (error) => {
          const clUploadError = new ClUploadExecutionError(
            "Failed to generate signed URL",
            { cause: error },
          );
          reject(clUploadError);
        },
      );
    function abortHandler(e: AbortSignalEventMap["abort"]) {
      const clUploadError = new ClUploadExecutionError("Upload aborted", {
        cause: e,
      });
      resolvers.reject(clUploadError);
    }
    function cleanup() {
      options.signal?.removeEventListener("abort", abortHandler);
    }
    options.signal?.addEventListener("abort", abortHandler);
    return {
      promise: resolvers.promise,
      eventEmitter,
    };
  }
}

export { ClUploadExecution };
