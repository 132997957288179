import type { ClApi } from "../ClApi";

import { computed, type ReadableAtom } from "nanostores";
import { ClSdk } from "../../ClSdk";
import { $dialogueId } from "../../store/dialogueIdStore";
import { $siteBoundUserUuid } from "../../store/siteBoundUserUuidStore";
import { createQueryStore } from "../ClApi/createQueryStore";
import { ClDebug } from "../ClDebug";

namespace ClAuth {
  export interface Options {
    clSdk: ClSdk;
  }
}

/**
 * ClAuth handles user authentication with the server.
 * It maintains the user's authenticated state and re-authenticates as needed.
 * Re-authentication triggers:
 * - Token `expiration`.
 * - `siteBoundUserUuid` change
 * - User becomes `unauthenticated`
 */
class ClAuth {
  static RETRIES = 1000;
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  private destroyTasks: Array<() => void> = [];
  $authenticateQuery: createQueryStore.QueryStore<
    Awaited<ReturnType<ClApi["auth"]["authenticate"]>>
  >;
  $token: ReadableAtom<
    null | Awaited<ReturnType<ClApi["auth"]["authenticate"]>>["token"]
  >;
  $dialogueId: ReadableAtom<
    null | Awaited<ReturnType<ClApi["auth"]["authenticate"]>>["dialogueId"]
  >;
  $edId: ReadableAtom<
    null | Awaited<ReturnType<ClApi["auth"]["authenticate"]>>["edId"]
  >;
  $nonce: ReadableAtom<
    null | Awaited<ReturnType<ClApi["auth"]["authenticate"]>>["nonce"]
  >;
  constructor(options: ClAuth.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({ clSdk: this.clSdk, module: "ClAuth" });
    this.clDebug.debug("Initialized");
    const abortController = new AbortController();
    this.$authenticateQuery = createQueryStore({
      query: async () =>
        this.clSdk.clApi.auth.authenticate(
          {
            sdkVersion: ClSdk.version,
            clWebChannelId: this.clSdk.clWebChannelId,
            siteBoundUserUuid: $siteBoundUserUuid.get(),
          },
          {
            signal: abortController.signal,
          },
        ),
    });
    this.destroyTasks.push(() => {
      abortController.abort();
    });
    this.destroyTasks.push(
      this.$authenticateQuery.subscribe((query) => {
        if (query.status !== "success") return;
        return $dialogueId.set(query.data.dialogueId);
      }),
    );
    this.$token = computed(this.$authenticateQuery, (query) => {
      if (query.status !== "success") return null;
      return query.data.token;
    });
    this.$dialogueId = computed(this.$authenticateQuery, (query) => {
      if (query.status !== "success") return null;
      return query.data.dialogueId;
    });
    this.$edId = computed(this.$authenticateQuery, (query) => {
      if (query.status !== "success") return null;
      return query.data.edId;
    });
    this.$nonce = computed(this.$authenticateQuery, (query) => {
      if (query.status !== "success") return null;
      return query.data.nonce;
    });
  }
  destroy = () => {
    this.destroyTasks.forEach((task) => task());
  };
}

export { ClAuth };
