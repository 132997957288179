import { AxiosError, CanceledError } from "axios";
import { type AnyStore, atom, type ReadableAtom, type Store } from "nanostores";
import { effect } from "../../utils/nanostores/effect";
import { sleep } from "../../utils/sleep";

namespace createQueryStore {
  export type GetDepsValueFromDepsStore<TDeps extends Array<AnyStore>> = {
    [TKey in keyof TDeps]: TDeps[TKey] extends AnyStore<infer U> ? U : never;
  };
  export interface QueryOptions<TValue, TDeps extends Array<AnyStore> = []> {
    deps?: TDeps;
    enabled?: (options: {
      deps: GetDepsValueFromDepsStore<TDeps>;
      signal: AbortSignal;
    }) => boolean;
    query: (options: {
      deps: GetDepsValueFromDepsStore<TDeps>;
      signal: AbortSignal;
    }) => Promise<TValue>;
    retryMs?:
      | number
      | ((args: { attemptIndex: number; error: unknown }) => number);
    maxAttempts?: number;
  }
  export type Result<TValue> =
    | {
        status: "success";
        data: TValue;
        error: null;
      }
    | {
        status: "error";
        data: null;
        error: unknown;
      }
    | {
        status: "loading";
        data: null;
        error: null;
      };
  export type QueryStore<TValue> = ReadableAtom<Result<TValue>>;
}

const defaultRetryMs = ((args) =>
  Math.min(
    1000 * 2 ** args.attemptIndex,
    30000,
  )) satisfies createQueryStore.QueryOptions<unknown>["retryMs"];

const createQueryStore = <
  const TValue,
  const TDeps extends Array<Store<any>> = [],
>({
  deps = [] as unknown as TDeps,
  enabled,
  query,
  retryMs = defaultRetryMs,
  maxAttempts = Infinity,
}: createQueryStore.QueryOptions<
  TValue,
  TDeps
>): createQueryStore.QueryStore<TValue> => {
  const $query = atom<
    | {
        status: "success";
        data: TValue;
        error: null;
      }
    | {
        status: "error";
        data: null;
        error: unknown;
      }
    | {
        status: "loading";
        data: null;
        error: null;
      }
  >({
    status: "loading",
    data: null,
    error: null,
  });
  effect(deps, (...deps) => {
    const abortController = new AbortController();
    if (
      enabled &&
      !enabled({
        deps,
        signal: abortController.signal,
      })
    ) {
      $query.set({ status: "loading", data: null, error: null });
      return;
    }
    (async () => {
      let attemptIndex = -1;
      while (!abortController.signal.aborted) {
        attemptIndex++;
        try {
          const data = await query({
            deps,
            signal: abortController.signal,
          });
          $query.set({ status: "success", data, error: null });
          break;
        } catch (error) {
          if (error instanceof CanceledError) {
            break;
          }
          if (error instanceof AxiosError) {
            const is4xxError =
              typeof error.response?.status === "number" &&
              error.response.status >= 400 &&
              error.response.status < 500;
            if (is4xxError) {
              $query.set({ status: "error", data: null, error });
              break;
            }
          }
          if (attemptIndex >= maxAttempts - 1) {
            $query.set({ status: "error", data: null, error });
            break;
          }
          const ms =
            typeof retryMs === "function"
              ? retryMs({ attemptIndex, error })
              : retryMs;
          await sleep({
            ms,
          });
          continue;
        }
      }
    })();
    return () => {
      abortController.abort();
      $query.set({ status: "loading", data: null, error: null });
    };
  });
  return $query;
};

export { createQueryStore };
